type ssrReturnType = {
  isBrowser: boolean;
  isServer: boolean;
};

const useSsr = (): ssrReturnType => {
  const isDOM =
    typeof window !== 'undefined' &&
    window.document &&
    window.document.documentElement;

  return {
    isBrowser: !!isDOM,
    isServer: !isDOM,
  };
};

export default useSsr;
