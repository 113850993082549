import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import { Accordion } from '@keytrade/components-accordion';
import RichText from '@/components/Richtext';

import Title from '@/components/Title';
import Paragraph from '@/components/Paragraph';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

type CostTableRowType = {
  title: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  titleDescription: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  value: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  valueDescription: RenderRichTextData<ContentfulRichTextGatsbyReference>;
};

type Props = {
  title: string;
  titleDescription: string;
  rows: Array<CostTableRowType>;
  footer: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  expandCollapse: boolean;
  dark: boolean;
};

type MarginBottomType = {
  marginBottom?: boolean;
  dark?: boolean;
};

type MarginTopType = {
  marginTop?: boolean;
  dark?: boolean;
};

type ItalicType = {
  small?: boolean;
  dark?: boolean;
};

const Wrapper = styled.div`
  margin-bottom: 3.2rem;
`;

const TitleWrapper = styled.div<MarginBottomType>`
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 2rem;'};
`;

const TableWrapper = styled.div<MarginBottomType>`
  border: 1px solid
    ${({ dark }) => (dark ? colors.BlackTransparentMedium : colors.GreyWhite)};
  border-radius: 12px;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 2rem;'};
`;
type RowProps = {
  withMargin: boolean;
  dark?: boolean;
};
const Row = styled.div<RowProps>`
  padding: 1.6rem 2rem;

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ dark }) => (dark ? colors.BlackTransparentMedium : colors.GreyWhite)};
  }
  ${({ withMargin }) =>
    withMargin &&
    `
    > *:nth-child(3) {
      margin-top: 1.6rem; 
    }

      > *:nth-child(3) {
        margin-top: 0; 
      }

      > *:nth-child(2), > *:nth-child(4) {
        margin-top: .4rem;
      }
  `}

  display: grid;
  grid-template-columns: 1fr 1fr;

  > *:nth-child(1),
  > *:nth-child(2) {
    grid-column-start: 1;
  }

  > *:nth-child(3),
  > *:nth-child(4) {
    grid-column-start: 2;
    text-align: right;
  }

  > *:nth-child(3) {
    grid-row-start: 1;
  }
`;

const Description = styled.p<MarginTopType>`
  ${({ marginTop }) => marginTop && 'margin-top: .6rem;'};
  font-size: 1.4rem;
  color: ${({ dark }) => (dark ? 'white' : colors.BlueMoon)};
  font-family: ${({ theme }) => getFont(theme, 'regular')};
`;

const Italic = styled.span<ItalicType>`
  text-transform: uppercase;
  font-size: ${({ small }) => (small ? '1.2rem' : '1.4rem')};
  color: ${({ dark }) => (dark ? 'white' : colors.GreyBlue)};

  font-family: ${({ theme }) => getFont(theme, 'regular')};

  &:not(:first-child) {
    margin-left: 0.8rem;
  }

  &:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

const CostTable: React.FC<Props> = ({
  title,
  titleDescription,
  rows,
  footer,
  expandCollapse,
  dark,
}) => {
  const theme = dark ? 'dark' : 'white';

  const TitleSection: React.FC<MarginBottomType> = ({ marginBottom }) => (
    <TitleWrapper marginBottom={marginBottom}>
      <Title size='sm' level='h3' lightColor={dark}>
        {title}
      </Title>
      {titleDescription && (
        <Description dark={dark}>{titleDescription}</Description>
      )}
    </TitleWrapper>
  );

  const titleCustomRenderer = {
    Paragraph: function CustomParagraph(node, children) {
      return (
        <Paragraph
          size='sm'
          weight='semiBold'
          color={dark ? 'White' : 'GreyBlue'}
        >
          {children}
        </Paragraph>
      );
    },
    Italic: function CustomItalic(node) {
      return (
        <Italic small dark={dark}>
          {node.props.children.props.children}
        </Italic>
      );
    },
  };

  const valueCustomRenderer = {
    Paragraph: function CustomParagraph(node, children) {
      return (
        <Paragraph size='sm' color={dark ? 'White' : 'BlueMoon'}>
          {children}
        </Paragraph>
      );
    },
    Italic: function CustomItalic(node) {
      return <Italic dark={dark}>{node.props.children.props.children}</Italic>;
    },
  };

  const descriptionCustomRenderer = {
    Paragraph: function CustomParagraph(node, children) {
      return <Description dark={dark}>{children}</Description>;
    },
  };

  const footerCustomRenderer = {
    Paragraph: function CustomParagraph(node, children) {
      return (
        <Description marginTop dark={dark}>
          {children}
        </Description>
      );
    },
  };
  const TableSection: React.FC<MarginBottomType> = ({ marginBottom }) => (
    <>
      <TableWrapper marginBottom={marginBottom} dark={dark}>
        {rows.map((row, index) => (
          <Row
            key={title + '-' + index}
            withMargin={row.value !== null || row.valueDescription !== null}
            dark={dark}
          >
            <RichText
              data={row.title}
              options={{ textSize: 'sm', theme: theme }}
              customComponents={titleCustomRenderer}
            />
            <div>
              {row.titleDescription && (
                <RichText
                  data={row.titleDescription}
                  options={{ textSize: 'sm', theme: theme }}
                  customComponents={descriptionCustomRenderer}
                />
              )}
            </div>
            <div>
              {row.value && (
                <RichText
                  data={row.value}
                  options={{ textSize: 'sm', theme: theme }}
                  customComponents={valueCustomRenderer}
                />
              )}
            </div>
            <div>
              {row.valueDescription && (
                <RichText
                  data={row.valueDescription}
                  options={{ textSize: 'sm', theme: theme }}
                  customComponents={descriptionCustomRenderer}
                />
              )}
            </div>
          </Row>
        ))}
      </TableWrapper>
      {footer && (
        <RichText
          data={footer}
          options={{ textSize: 'sm', theme: theme }}
          customComponents={footerCustomRenderer}
        />
      )}
    </>
  );

  return expandCollapse ? (
    <Accordion
      margin='0 0 3.2rem 0'
      data={[
        {
          header: <TitleSection />,
          content: <TableSection />,
        },
      ]}
    />
  ) : (
    <Wrapper>
      <TitleSection marginBottom />
      <TableSection />
    </Wrapper>
  );
};

export default CostTable;

export const costTableRowFragment = graphql`
  fragment CostTableRowFragment on ContentfulCostTableRow {
    title {
      raw
    }
    titleDescription {
      raw
    }
    value {
      raw
      references {
        ... on ContentfulColoredText {
          contentful_id
          __typename
          title
          color
        }
        ...SavingRatesRichTextFragment
      }
    }
    valueDescription {
      raw
    }
  }
`;

export const costTableQuery = graphql`
  fragment CostTableRichTextFragment on ContentfulCostTable {
    __typename
    contentful_id
    title
    titleDescription
    rows {
      ...CostTableRowFragment
    }
    footer {
      raw
    }
    expandCollapse
  }
`;
