import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { getTransparentColor } from '@/utils/StyleUtils';
import Paragraph from './Paragraph';
import { config } from '@keytrade/functions';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';
import Image from '@/components/Image';
import { AuthorType } from '@/components/blog/Author';
import { mediaQuery } from '@/utils/helpers';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

const QuoteContent = styled.blockquote`
  padding: 0 0 0 2rem;
  margin: 0;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: ${getTransparentColor('Blue cloudy')};
    border-radius: 1px;
    height: 100%;
    width: 2px;
  }
`;
type QuoteTextProps = {
  dark: boolean;
};
const QuoteText = styled.p<QuoteTextProps>`
  width: auto;
  font-size: 2.4rem;
  color: ${({ dark }) => (dark ? 'white' : colors.BlueDark)};
  font-family: ${({ theme }) => getFont(theme, 'italic')};
`;

const QuoteWrapper = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  margin: 3.2rem 0;
`;
const QuotePictureWrapper = styled.div`
  flex-shrink: 0;
  display: none;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 48px;
  ${mediaQuery.md(`
    display: flex;
  `)}
`;
const QuotePicture = styled(Image)`
  object-fit: fill;
  object-position: center;
`;

export type QuoteType = {
  text: { text: string };
  author: AuthorType;
  dark?: boolean;
  showPicture: boolean;
};
type Props = QuoteType;

const Quote: React.FC<Props> = ({ text, author, dark, showPicture }) => {
  return (
    <QuoteWrapper>
      {showPicture && author && (
        <QuotePictureWrapper>
          <QuotePicture {...author.picture}></QuotePicture>
        </QuotePictureWrapper>
      )}
      <QuoteContent>
        <QuoteText dark={dark}>
          <CurrencyFormatter>{text.text}</CurrencyFormatter>
        </QuoteText>
        {author && (
          <footer>
            <Paragraph
              size='sm'
              color={dark ? 'White' : 'GreyBlue'}
              margin='1.2rem 0 0 0'
            >
              {author.name}, {author.role}
            </Paragraph>
          </footer>
        )}
      </QuoteContent>
    </QuoteWrapper>
  );
};

export default Quote;

export const quoteQuery = graphql`
  fragment QuoteRichTextFragment on ContentfulQuote {
    contentful_id
    __typename
    text {
      text
    }
    showPicture
    author {
      name
      role
      picture {
        gatsbyImageData
        ...ImageFragment
      }
    }
  }
`;
