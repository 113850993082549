import React from 'react';
import { graphql } from 'gatsby';

import InternalLink from './InternalLink';
import ExternalLink from './ExternalLink';

import { generateSlug } from '@/utils/SlugUtils';
import { PageInfoType } from '@/components/Page';
import {
  INTERNAL_LINK,
  EXTERNAL_LINK,
  COOKIE_CATEGORIES,
} from '@/utils/constants';
import { trackEvent } from '../utils/matomoHelpers';
import { EventTrackingType } from '../types/EventTrackingTypes';
import { ImageType } from './Image';

type ContentfulEventTracking = {
  contentful_id?: string;
  category: string;
  action: string;
  name: string;
  value: string;
};

type CallbackParameter = string | ((e: MouseEvent) => void);

export const BLOCK_TYPE = 'block';
export const INLINE_TYPE = 'inline';

const runCallback = (callback: CallbackParameter, e: MouseEvent) => {
  if (!callback) {
    return;
  }

  switch (typeof callback) {
    case 'string':
      window.open(callback, '_blank');
      break;
    case 'function':
      if (!e) {
        return;
      }

      callback(e);
      break;
    default:
      return;
  }
};

export const callbackFn = (
  e: MouseEvent,
  eventTracking: ContentfulEventTracking,
  paramFn: string | ((e: MouseEvent) => void),
): void => {
  const isOneTrustReady = typeof window?.['OnetrustActiveGroups'] === 'string';

  if (
    eventTracking?.category &&
    eventTracking?.action &&
    isOneTrustReady &&
    window?.['OnetrustActiveGroups'].includes(COOKIE_CATEGORIES.ANALYTIC)
  ) {
    trackEvent(
      eventTracking.category,
      eventTracking.action,
      eventTracking.name,
      eventTracking.value,
      () => null,
    );
    return;
  }
};

export type LinkType = {
  __typename: string;
  page?: PageInfoType;
  url?: string;
  linkText?: string;
  image?: ImageType;
  linkStyle: LinkStyle;
  fullWidth?: boolean;
  promo?: boolean;
  margin?: string;
  block?: {
    id: string;
  };
  inline?: boolean;
  onClick?: any;
  eventTracking?: EventTrackingType;
};

export enum LinkStyle {
  PRIMARY = 1,
  SECONDARY = 2,
  CONTENT = 3,
  IMAGE = 4,
  BACK = 5,
  INLINE = 6,
  WHITE = 7,
  INTERNAL = 8,
  FOOTERLINK = 9,
}

export const createInternalLink = (
  page: PageInfoType,
  linkStyle: LinkStyle,
  linkText: string = null,
): LinkType => {
  return {
    linkText: linkText,
    page: page,
    __typename: INTERNAL_LINK,
    linkStyle: linkStyle,
  };
};

type Props = LinkType;

const Link: React.FC<Props> = (props) => {
  if (props.__typename === INTERNAL_LINK)
    return (
      <InternalLink
        id={props.block?.id}
        to={generateSlug(props.page)}
        linkStyle={props.linkStyle}
        fullWidth={props.fullWidth}
        margin={props.margin}
        promo={props.promo}
        onClick={props.onClick}
        inline={props?.inline}
        eventTracking={props?.eventTracking}
        linkText={props.linkText}
      >
        {props.children || props.linkText}
      </InternalLink>
    );
  else if (props.__typename === EXTERNAL_LINK)
    return (
      <ExternalLink
        url={props.url}
        image={props.image}
        fullWidth={props.fullWidth}
        margin={props.margin}
        linkStyle={props.image === null ? props.linkStyle : LinkStyle.IMAGE}
        eventTracking={props?.eventTracking}
        linkText={props.linkText}
      >
        {props.children || props.linkText}
      </ExternalLink>
    );
  return <></>;
};

export default Link;

export const linkQuery = graphql`
  fragment LinkFragment on ContentfulExternalLinkContentfulInternalLinkUnion {
    ... on ContentfulInternalLink {
      contentful_id
      __typename
      node_locale
      linkText
      eventTracking {
        id
        contentful_id
        category
        action
        name
        value
      }
      block {
        ... on ContentfulTopBlock {
          id
        }
        ... on ContentfulLargeCardsBlock {
          id
        }
        ... on ContentfulSingleCenteredBlock {
          id
        }
        ... on ContentfulSingleLargeImageBlock {
          id
        }
        ... on ContentfulImagesSwitchingSidesBlock {
          id
        }
        ... on ContentfulSmallCardsBlock {
          id
        }
        ... on ContentfulTilesBlock {
          id
        }
        ... on ContentfulTopBlockSelector {
          id
        }
        ... on ContentfulTabPagesBlock {
          id
        }
        ... on ContentfulArticlesBlock {
          id
        }
        ... on ContentfulHelpBlock {
          id
        }
        ... on ContentfulStepsBlock {
          id
        }
        ... on ContentfulMediumCardsBlock {
          id
        }
        ... on ContentfulPicturesBlock {
          id
        }
        ... on ContentfulKeyplanSimulationBlock {
          id
        }
        ... on ContentfulRichTextBlock {
          id
        }
        ... on ContentfulContactBlock {
          id
        }
        ... on ContentfulIconBenefitsBlock {
          id
        }
        ... on ContentfulVisaComparisonTableBlock {
          id
        }
        ... on ContentfulKeyprivateSimulationBlock {
          id
        }
        ... on ContentfulJobListingBlock {
          id
        }
        ... on ContentfulSeminarListingBlock {
          id
        }
        ... on ContentfulSeminarTopicSBlock {
          id
        }
      }
      page {
        slug
        node_locale
        name
        parentPage {
          ... on ContentfulPage {
            slug
            parentPage {
              ... on ContentfulPage {
                slug
                pageType {
                  ... on ContentfulInfoPageType {
                    id
                    name
                  }
                }
                parentPage {
                  ... on ContentfulPage {
                    slug
                    parentPage {
                      ... on ContentfulPage {
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ... on ContentfulExternalLink {
      __typename
      linkText
      url
      image {
        gatsbyImageData
        ...ImageFragment
      }
      eventTracking {
        id
        contentful_id
        category
        action
        name
        value
      }
    }
  }
`;
