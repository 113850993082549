import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import Container from '@/components/Container';
import { NavigationCategories } from './Navigation';
import Link, { LinkStyle } from '@/components/Link';

const SCROLL_TOLERANCE = 200; // We accept a certain scroll offset from the initial position.

type activeProp = {
  scrolled: boolean;
};

const Caret = styled.div<activeProp>`
  position: absolute;
  display: block;
  left: calc(50% - 10px);
  bottom: ${({ scrolled }) => (scrolled ? '-13px' : '0')};
  z-index: 1;

  &:before {
    content: ' ';
    position: absolute;
    top: -20px;
    width: 0;
    height: 0;

    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff;

    filter: drop-shadow(0px -1px 1.5px rgb(0 0 0 / 8%));
  }
  &:after {
    content: ' ';
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    top: 0;
    background-color: white;
    left: 0;
  }
`;

const DropdownBackground = styled.div`
  width: 100%;
  transform-origin: 0 0;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.08),
    0px 16px 24px 0px rgba(0, 0, 0, 0.16);
`;

const DropdownContainer = styled.div<activeProp>`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  perspective: 1000px;
  top: ${({ scrolled }) => (scrolled ? '12.6rem' : 'auto')};
`;
const DropdownContent = styled.div`
  padding: 32px 48px 60px 48px;
  background-color: #fff;
`;
const FooterLinks = styled.ul`
  margin: 0;
  padding: 0;
  text-align: left;
  display: flex;
  wrap: nowrap;
  flex-direction: row;
  align-items: start;
  background-color: #f1f5f7;
  padding: 20px 48px;
`;
const FooterLink = styled.li`
  list-style-type: none;
  width: 100%;
  margin-right: 38px;
  max-width: 222px;
  &:last-of-type {
    margin-right: 0;
  }
`;

type Props = {
  children: any;
  category: NavigationCategories;
  scrolled: boolean;
  handleClose: any;
  navigationRef: any;
  refs: any;
};

const SubCategoryContainer: React.FC<Props> = ({
  id,
  children,
  category,
  scrolled,
  handleClose,
  navigationRef,
  refs,
  __typename,
}) => {
  const ref = useRef<any>();

  const initialScroll = window.scrollY;

  const handleScroll = () => {
    let scrolled;
    if (window.scrollY < initialScroll) {
      // Scrolling Up.
      scrolled = initialScroll - window.scrollY;
    } else {
      // Scrolling Down.
      scrolled = window.scrollY - initialScroll;
    }
    if (scrolled > SCROLL_TOLERANCE) {
      handleClose();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window.scrollY]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      refs.map((navRef) => {
        if (
          (ref.current && ref.current.contains(e.target)) ||
          (navigationRef.current && navigationRef.current.contains(e.target)) ||
          (navRef.contains(e.target) &&
            !navigationRef.current.contains(e.target))
        ) {
          return;
        }
        handleClose();
      });
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });
  return (
    <>
      <Caret scrolled={scrolled} />

      <DropdownContainer scrolled={scrolled} id={id}>
        <Container>
          <DropdownBackground ref={ref}>
            <DropdownContent>{children}</DropdownContent>
            {category.footerLinks && (
              <FooterLinks>
                {category.footerLinks.map((footerLink, i) => (
                  <FooterLink key={i}>
                    <Link
                      __typename={__typename}
                      linkStyle={LinkStyle.FOOTERLINK}
                      {...footerLink}
                    />
                  </FooterLink>
                ))}
              </FooterLinks>
            )}
          </DropdownBackground>
        </Container>
      </DropdownContainer>
    </>
  );
};

export default SubCategoryContainer;
