import React, { ReactNode } from 'react';

import { MAIN_CONTENT_ID } from './htmlIds.contants';

type Props = {
  children?: ReactNode | undefined;
};

const Main: React.FC<Props> = ({ children }) => (
  <main id={MAIN_CONTENT_ID}>{children}</main>
);

export default Main;
