import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { drawWidget } from '../../static/scripts/scriptUtils';

export type ExternalContentType = {
  name: string;
  content: string;
  locale: 'en' | 'fr' | 'nl';
};

const ExternalContentWrapper = styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  margin: 0 auto;
  table,
  table * {
    font-family: 'Averta' !important;
  }
  table th {
    white-space: nowrap !important;
  }
`;

const WidgetTarget = styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  margin: 0 auto;
`;

type Props = ExternalContentType;

const ExternalContent: React.FC<Props> = (props) => {
  const EXT_LIST = [
    {
      label: 'futureTariffs',
      id: 'f75a1071-fc9c-4d29-9243-6acc9d264e2d',
    },
  ];

  const [currentLang, setCurrentLang] = useState('');
  const [currentHtmlLang, setCurrentHtmlLang] = useState('');

  useEffect(() => {
    setCurrentHtmlLang(
      document.getElementsByTagName('html')[0].getAttribute('lang'),
    );
  }, []);

  useEffect(() => {
    setCurrentLang(props.locale || currentHtmlLang);
  }, [currentHtmlLang]);

  useEffect(() => {
    drawWidget(EXT_LIST[0].id, currentLang);
  }, [currentLang]);

  const renderContent = (props) => {
    switch (props.ExternalContent) {
      // futureTariffs
      case EXT_LIST[0].label:
        const returnId = `widget${EXT_LIST[0].id}${currentLang}`;
        return <WidgetTarget id={returnId}></WidgetTarget>;
      default:
        return;
    }
  };

  return (
    <ExternalContentWrapper>{renderContent(props)}</ExternalContentWrapper>
  );
};

export default ExternalContent;

export const externalContentQuery = graphql`
  fragment ExternalContentRichTextFragment on ContentfulExternalContent {
    contentful_id
    __typename
    name
    content
  }
`;
