import React from 'react';
import { graphql } from 'gatsby';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import { JSONPath } from 'jsonpath-plus';
import slugify from 'slugify';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import { List } from '@keytrade/components-list';

const { list: colors } = config.colors;

const Header = styled.a`
  display: inline-block;
  text-decoration: none;
  color: ${colors.BlueDawn};
`;

export type TableOfContentType = {
  data: RenderRichTextData<ContentfulRichTextGatsbyReference>;
};

type Props = {
  data: any;
};
type TableOfContentHeaderType = {
  type?: string;
  title?: string;
  slug?: string;
  children?: Array<TableOfContentHeaderType>;
};

const TableOfContent: React.FC<Props> = ({ data }) => {
  const result = JSONPath({
    path: '$..[?(/heading/.test(@.nodeType))]',
    json: JSON.parse(data.raw),
  });
  const tableOfContentHeaders = [];
  result.forEach((element) => {
    const headerObject = {
      type: element.nodeType,
      title: element.content[0].value.replace(/(\d?\.?)+\s?/, ''),
      slug: slugify(element.content[0].value),
    };
    tableOfContentHeaders.push(headerObject);
  });

  const getTree = (arr: Array<TableOfContentHeaderType>) => {
    const levels: Array<TableOfContentHeaderType> = [{}];
    const lowestLevel = Math.min(
      ...arr.map((el) => parseInt(el.type.substring(8), 10)),
    );
    arr.forEach((element) => {
      try {
        const level = parseInt(element.type.substring(8), 10);
        const normalizedLevel = level - lowestLevel + 1;
        levels.length = normalizedLevel;
        levels[normalizedLevel - 1].children =
          levels[normalizedLevel - 1].children || [];
        levels[normalizedLevel - 1].children.push(element);
        levels[normalizedLevel] = element;
      } catch (err) {
        console.error(err);
      }
    });
    return levels[0].children;
  };

  const renderElement = (
    element: TableOfContentHeaderType,
    counter: number,
  ) => {
    return (
      <>
        <div>
          <Header href={`#${element.slug}`}>{element.title}</Header>
          {element.children && element.children.length && (
            <List type='ordered'>
              {element.children.map((childElement) =>
                renderElement(childElement, counter + 1),
              )}
            </List>
          )}
        </div>
      </>
    );
  };
  return (
    <List type='ordered' size='lg'>
      {tableOfContentHeaders &&
        tableOfContentHeaders.length &&
        getTree(tableOfContentHeaders).map((element) =>
          renderElement(element, 1),
        )}
    </List>
  );
};
export default TableOfContent;
export const costTableQuery = graphql`
  fragment TableOfContentFragment on ContentfulTableOfContent {
    __typename
    contentful_id
    name
  }
`;
