import React from 'react';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import { NavigationCategories } from '@/components/navigation/Navigation';
import { Icon } from '@keytrade/components-icon';
import MobileSubCategory from '@/components/navigation/MobileSubCategory';
import Paragraph from '@/components/Paragraph';
import SubCategoryLink from '@/components/navigation/SubCategoryLink';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

type ActiveProps = {
  active: boolean;
};

const CategoryContainer = styled.div``;
const NavigationCategoryContainer = styled.ul`
  padding-left: 0;
  margin: 0;
`;

const CategoryElement = styled.li`
  list-style-type: none;
`;
const CategoryLink = styled.button<ActiveProps>`
  background-color: transparent;
  border-color: transparent;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 2rem;
  line-height: 2.8rem;
  font-family: ${({ theme }) => getFont(theme, 'bold')};
  color: ${colors.BlueDark};
  padding: 2.2rem 0 2.2rem 6rem;

  &:after {
    content: '';
    position: absolute;
    display: ${({ active }) => (active ? 'none' : 'block')};
    bottom: 0;
    right: 0;
    height: 1px;
    width: calc(100% - 60px);
    background-color: ${colors.BlueLight};
  }
`;

const SubCategoryDropList = styled.ul`
  padding: 2rem;
  margin: 0;
`;
const SubCategoryContainer = styled.div<ActiveProps>`
  overflow: hidden;
  background-color: ${colors.BlueLight};
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  ${({ active }) => (active ? 'max-height: 100rem;' : 'max-height: 0;')}
`;
const IconContainer = styled.span`
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -10px;
  margin-left: -10px;
`;

const ArrowIcon = styled(Icon)<ActiveProps>`
  transition: transform 0.2s ease;
  ${({ active }) =>
    active
      ? `
  transform:scaleY(-1) translateY(0.2rem);
`
      : `
      transform:scaleY(1);
`};
`;
const TitleLink = styled.div`
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${colors.BlueFog};
  }
`;

export type Props = {
  category: NavigationCategories;
  isOpen: boolean;
  handleClick: any;
};

const MobileCategory: React.FC<Props> = ({ category, handleClick, isOpen }) => {
  return (
    <CategoryContainer>
      <NavigationCategoryContainer>
        <CategoryElement>
          <CategoryLink active={isOpen} onClick={handleClick}>
            <IconContainer>
              <ArrowIcon
                icon='icn_arrowDown'
                color={colors.BlueDark}
                size='2.4rem'
                active={isOpen}
              />
            </IconContainer>
            {category.category}
          </CategoryLink>
        </CategoryElement>
      </NavigationCategoryContainer>

      <SubCategoryContainer active={isOpen}>
        <SubCategoryDropList>
          <TitleLink>
            <Paragraph>
              <SubCategoryLink
                prefix={category.titlePrefix}
                {...category.title}
              />
            </Paragraph>
          </TitleLink>
          {category.subCategories.map((navSubCategory, i) => (
            <MobileSubCategory subCategory={navSubCategory} key={i} />
          ))}
        </SubCategoryDropList>
      </SubCategoryContainer>
    </CategoryContainer>
  );
};

export default MobileCategory;
