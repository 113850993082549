import React from 'react';
import styled from 'styled-components';

import { mediaQuery } from '@/utils/helpers';
import { PageInfoType } from '@/components/Page';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';
import { generateSlug } from '@/utils/SlugUtils';
import { config } from '@keytrade/functions';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

export type Props = {
  __typename: string;
  page?: PageInfoType;
  url?: string;
  linkText?: string;
};

const NavigationLinkWrapper = styled(GatsbyLink)`
  display: flex;
  justif-content: center;
  align-items: center;
  padding: 1.2rem 0;
  font-size: 1.6rem;
  font-family: ${({ theme }) => getFont(theme, 'semiBold')};
  color: ${colors.BlueDark};
  text-decoration: none;
  cursor: pointer;

  ${mediaQuery.lg(`
    font-size: 1.3rem;
    color: ${colors.BlueLight};
    padding: 0 16px;
    height: 100%;
    
  `)}

  ${({ theme }) =>
    theme.dark
      ? `
    ${mediaQuery.lg(`
      &:hover {
        background-color: ${colors.BlueMoon};
      }
    `)}
  `
      : `
    ${mediaQuery.lg(`
      &:hover {
        background-color: ${colors.BlueDark};
      }
    `)}
  `}
`;

const NavigationLink: React.FC<Props> = (props) => {
  return (
    <NavigationLinkWrapper to={generateSlug(props.page)}>
      {props.linkText}
    </NavigationLinkWrapper>
  );
};

export default NavigationLink;
