import React from 'react';
import styled from 'styled-components';
import { Icon } from '@keytrade/components-icon';

import Paragraph from '@/components/Paragraph';
import useTranslations from '@/hooks/useTranslations';

const NoResultsWrapper = styled.div`
  padding: 4rem;
  text-align: center;
`;

const NoResults: React.FC = () => {
  const { t } = useTranslations('search');

  return (
    <NoResultsWrapper>
      <Icon icon='img_noResults' size='80px' />
      <Paragraph weight='semiBold'>{t('no_results_1')}</Paragraph>
      <Paragraph>{t('no_results_2')}</Paragraph>
    </NoResultsWrapper>
  );
};

export default NoResults;
