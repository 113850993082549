import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import LoginSubLink from '@/components/navigation/LoginSubLink';
import { LoginData } from '@/components/navigation/Navigation';
import LoginPanelForm from '@/components/navigation/LoginPanelForm';

const { list: colors } = config.colors;
const Panel = styled.div`
  position: absolute;
  bottom: 0;
  background: ${colors.White};
  top: 63px;
  right: 0;
  width: 360px;
`;
const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 12px;
  background: ${colors.White};
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.08),
    0px 16px 24px 0px rgba(0, 0, 0, 0.16);
`;

export type LoginPanelData = {
  loginData: LoginData;
  handleLogin: any;
  input: string;
  isChecked: boolean;
  setInput: any;
  setChecked: any;
  loginButtonRef: any;
  closeModal: any;
  formRef: any;
  formOptions: any;
  publicAuthURL: string;
  errorMessage: string;
};

export type Props = LoginPanelData;

const DesktopLoginPanel: React.FC<Props> = ({
  loginData,
  handleLogin,
  input,
  isChecked,
  setInput,
  setChecked,
  loginButtonRef,
  closeModal,
  formRef,
  formOptions,
  publicAuthURL,
  errorMessage,
}) => {
  const ref = useRef<any>();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        (ref.current && ref.current.contains(e.target)) ||
        (loginButtonRef.current && loginButtonRef.current.contains(e.target))
      ) {
        return;
      } else {
        closeModal();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });

  return (
    <Panel>
      <PanelWrapper ref={ref}>
        <LoginPanelForm
          loginData={loginData}
          handleLogin={handleLogin}
          isChecked={isChecked}
          input={input}
          setChecked={setChecked}
          setInput={setInput}
          formRef={formRef}
          formOptions={formOptions}
          publicAuthURL={publicAuthURL}
          errorMessage={errorMessage}
        />
        {loginData.hardkeyActivation ? (
          <LoginSubLink {...loginData.hardkeyActivation} />
        ) : (
          <LoginSubLink {...loginData.firstTime} />
        )}
      </PanelWrapper>
    </Panel>
  );
};

export default DesktopLoginPanel;
