"use strict";

exports.__esModule = true;
exports.localizedPath = exports.isLocalized = void 0;
var localizedPath = exports.localizedPath = function localizedPath(locale, path) {
  var _path$split = path.split('/'),
    base = _path$split[1];

  // If for whatever reason we receive an already localized path
  // (e.g. if the path was made with location.pathname)
  // just return it as-is.
  if (base === locale) {
    return path;
  }

  // If it's another language, prefix with the locale
  return "/" + locale + path;
};
var isLocalized = exports.isLocalized = function isLocalized(path, languages) {
  var _path$split2 = path.split('/'),
    base = _path$split2[1];
  return languages.includes(base);
};