import React from 'react';
import styled from 'styled-components';

import Richtext from '@/components/Richtext';
import Image from '@/components/Image';
import { Title } from '@keytrade/components-title';
import Paragraph from '@/components/Paragraph';
import { subCategories } from '@/components/navigation/Navigation';
import Link, { LinkStyle } from '@/components/Link';

export type Props = {
  subCategory: subCategories;
};

const SubCategoryElement = styled.li`
  list-style-type: none;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SubCategoryImageContent = styled.div`
  display: block;
  width: 72px;
  height: 72px;
  margin-right: 16px;
  top: 3px;
  position: relative;
  > * {
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }
`;
const SubCategoryImage = styled(Image)`
  isolation: isolate;
`;

const SubCategoryContent = styled.div`
  display: flex;
`;
//we get the 78px (size: 58px & 20px margin) - this is needed when we have an SVG
const SubCategoryInformation = styled.div`
  width: calc(100% - 78px);
`;

const MobileSubCategory: React.FC<Props> = ({ subCategory }) => {
  const richTextCustomRenderer = {
    Paragraph: function CustomParagraph(node, children) {
      return (
        <Paragraph size='sm' margin='0' color='BlueMoon'>
          {children}
        </Paragraph>
      );
    },
  };
  return (
    <SubCategoryElement>
      <Link {...subCategory.link} linkStyle={LinkStyle.CONTENT}>
        <SubCategoryContent>
          <SubCategoryImageContent>
            <SubCategoryImage {...subCategory.image} />
          </SubCategoryImageContent>
          <SubCategoryInformation>
            <Title level='h3' forceMobileSize='sm' size='md'>
              {subCategory.title}
            </Title>
            <Richtext
              data={subCategory.richText}
              customComponents={richTextCustomRenderer}
              options={{ textSize: 'xs' }}
            />
          </SubCategoryInformation>
        </SubCategoryContent>
      </Link>
    </SubCategoryElement>
  );
};

export default MobileSubCategory;
