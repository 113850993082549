import React from 'react';
import { graphql } from 'gatsby';
import { DownloadDocument } from '@keytrade/components-downloaddocument';
import { Link } from '@keytrade/components-link';

import { AssetData } from '@/types/AssetData';

export type AssetLinkType = {
  title?: string;
  asset: AssetData;
  margin?: string;
  inline?: boolean;
  underline?: boolean;
  size?: string;
};

type Props = AssetLinkType;

const AssetLink: React.FC<Props> = ({
  asset,
  margin,
  inline,
  title,
  underline,
  size,
}) =>
  inline ? (
    <Link
      href={asset.file.url}
      download
      margin={margin}
      targetBlank
      size={size || 'xl'}
      underline={underline}
    >
      {(title ??= asset.title)}
    </Link>
  ) : (
    <DownloadDocument href={asset.file.url} margin={margin}>
      {(asset.title ??= title)}
    </DownloadDocument>
  );

export default AssetLink;

export const assetLinkQuery = graphql`
  fragment AssetLinkRichTextFragment on ContentfulAssetLink {
    contentful_id
    __typename
    name
    title
    asset {
      title
      file {
        url
      }
    }
  }
`;
