import { config } from '@keytrade/functions';
const { list: colors } = config.colors;

const getBackgroundColor = (color: string): string => {
  switch (color) {
    case 'White':
      return colors.White;
    case 'Light blue':
      return colors.BlueLight;
    case 'Blue gradient':
      return colors.BlueLightGradient;
    case 'Dark blue':
      return colors.BlueDark;
    case 'Blue Moon':
      return colors.BlueMoon;
    case 'Dark blue gradient':
      return colors.BlueDarkGradient;
    case 'Blue Dark':
      return colors.BlueDark;
    case 'Blue Fog':
      return colors.BlueFog;
    case 'Grey Background':
      return colors.GreyBackground;
    case 'Highlight':
      return colors.YellowVanilla;
    case 'Transparent':
    default:
      return colors.Transparent;
  }
};

const getTransparentColor = (color: string): string => {
  switch (color) {
    case 'Black':
      return colors.BlackTransparent;
    case 'Back light':
      return colors.BlackTransparentLight;
    case 'Blue cloudy':
      return colors.BlueCloudyTransparent;
  }
};

const getFontColorByBackground = (backgroundColor: string): string => {
  switch (backgroundColor) {
    case 'White':
    case 'Light blue':
    case 'Blue gradient':
    case 'Transparent':
      return 'BlueDark';
    case 'Dark blue':
    case 'Dark blue gradient':
      return 'White';
  }
};
const isDarkTheme = (color: string): boolean => {
  switch (color) {
    case 'White':
    case 'Light blue':
    case 'Blue gradient':
    case 'Transparent':
      return false;
    case 'Dark blue':
    case 'Dark blue gradient':
    case 'Blue Dark':
      return true;
  }
};

export {
  getBackgroundColor,
  getTransparentColor,
  isDarkTheme,
  getFontColorByBackground,
};
