import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { config } from '@keytrade/functions';

import { mediaQuery } from '@/utils/helpers';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';

const { list: colors } = config.colors;

export type SavingRatesType = {
  rate: string;
  name: string;
  rateLabel?: string;
  dark?: boolean;
};

type Props = SavingRatesType;

const RatesContent = styled.div`
  display: inline;
  flex-direction: row;
  margin: 2.4rem 0;
  ${mediaQuery.sm(`
  flex-direction:row;
  gap: 1.6rem;
  flex-wrap: wrap;
  `)};
`;
type DarkProps = {
  dark: boolean;
};
const RateContent = styled.div<DarkProps>`
  display: inline-flex;
  flex-direction: row;
  max-width: 21.6rem;
  ${({ dark }) => (dark ? 'color:' + colors.White : '')};
  &:nth-child(2) {
    > *:first-child {
      color: ${colors.BlueSky};
    }
  }
`;
type RateValueProps = {
  sign: string;
  procent: string;
};
const RateValue = styled.div<RateValueProps>`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  white-space: nowrap;
  ${({ sign }) => {
    switch (sign) {
      case '+':
        return `
        &:before{
          content:"+";
          margin-right: 2px;
          
        }
        `;
      case '-':
        return `
        &:before{
          content:"-";
          margin-right: 2px;
        }
        `;
      default:
        return `
        `;
    }
  }};
  ${({ procent }) => {
    if (procent) {
      return `&:after {
        content: '%';
        margin-left: 2px;
        font-size: inherit;
      }`;
    }
  }}};
`;

const SavingRates: React.FC<Props> = ({ rate, name, dark }) => {
  const rateFn = (rate, name) => {
    let sign = '';
    let rateValue = rate;
    let procent = '';
    if (rate.charAt(0) === '+' || rate.charAt(0) === '-') {
      sign = rate.charAt(0);
      rateValue = rate.substring(1);
    }
    if (rate.includes('%')) {
      procent = '%';
      rateValue = rateValue.replaceAll('%', '');
    }
    rateValue = rateValue.trim();
    return (
      <RateContent dark={dark} title={name}>
        <RateValue sign={sign} procent={procent} title={name}>
          <CurrencyFormatter>{rateValue}</CurrencyFormatter>
        </RateValue>
      </RateContent>
    );
  };
  return <RatesContent>{rateFn(rate, name)}</RatesContent>;
};

export default SavingRates;

export const savingRatesQuery = graphql`
  fragment SavingRatesRichTextFragment on ContentfulSavingsRate {
    contentful_id
    __typename
    name
    rate
    rateLabel
  }
`;
