import React, { useState, useEffect } from 'react';

import { isProductionEnv } from '@/utils/envHelper';
import useTranslations from '@/hooks/useTranslations';
import { SiteMetaDataType, useSiteMetadata } from '@/hooks/useSiteMetadata';
import { Helmet } from 'react-helmet';

const CHATBOT_STORAGE_KEY = 'chatbot-language';

type Props = {
  locale: string;
};

const ChatBot: React.FC<Props> = ({ locale }) => {
  // Temporarily keep activation timeframe settings in case of issue on PROD
  const activationStartDate = new Date('2025-04-02T00:00:00.000').getTime();
  const activationEndDate = new Date('2099-12-31T23:59:59.999').getTime();
  const today = new Date().getTime();
  const isActivated = today > activationStartDate && today < activationEndDate;

  if (isProductionEnv && !isActivated) {
    return null;
  }

  const { t } = useTranslations('chatbot');
  const siteMetadata: SiteMetaDataType = useSiteMetadata();
  const [isReadyToInjectScript, setIsReadyToInjectScript] = useState(false);

  const cdnUrl = siteMetadata.cdnUrl;

  useEffect(() => {
    const wonderchatElement = document?.querySelector('#wonderchat-wrapper');

    if (!!wonderchatElement) {
      const currentLang = window?.sessionStorage?.getItem(CHATBOT_STORAGE_KEY);

      if (currentLang === locale) {
        return;
      } else {
        setTimeout(() => {
          window?.location?.reload();
        }, 0);

        return;
      }
    }

    setIsReadyToInjectScript(true);
  }, []);

  useEffect(() => {
    if (!isReadyToInjectScript) {
      return;
    }

    const element = document?.querySelector('body');
    const script = document?.createElement('script');

    script.src = `${cdnUrl}scripts/wonderchat.js`;
    script.dataset.name = 'wonderchat';
    script.dataset.address = 'app.wonderchat.io';
    script.dataset.id = 'clkv7a1010057mp21vn81zt7t';
    script.dataset.greeting = t('greeting');
    script.defer = true;
    script.async = true;

    element?.appendChild(script);
    window?.sessionStorage?.setItem(CHATBOT_STORAGE_KEY, locale);

    return () => {
      element?.removeChild(script);
    };
  }, [isReadyToInjectScript]);

  return (
    <Helmet>
      <style>
        {`
          @media (min-width:800px){
            #wonderchat-wrapper {
              bottom: 20px !important;
              right: 20px !important;
            }
          }

          #wonderchat-wrapper {
            z-index: 9999 !important;
            top: auto !important;
          }
        `}
      </style>
    </Helmet>
  );
};

export default ChatBot;
