import React from 'react';
import styled from 'styled-components';

import { Link } from '@keytrade/components-link';
import { Button } from '@keytrade/components-button';
import { Icon } from '@keytrade/components-icon';
import { config } from '@keytrade/functions';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';

import { LinkStyle, callbackFn } from './Link';
import Image, { ImageType } from '@/components/Image';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';
import { EventTrackingType } from '../types/EventTrackingTypes';

const css = require('styled-components').css;

type Props = {
  url: string;
  image?: ImageType;
  primaryLink?: boolean;
  linkStyle: LinkStyle;
  fullWidth?: boolean;
  margin?: string;
  locale?: string;
  onClick?: any;
  size?: string;
  eventTracking?: EventTrackingType;
};

const { list: colors } = config.colors;
const { getFont } = config.fonts;

const _BLANK = '_blank';

const ContentLink = styled.a`
  text-decoration: none;S
`;

const WhiteButton = styled(Button)`
  background-color: ${colors.White};
  border-color: ${colors.White};
  color: ${colors.BlueSky};

  &:hover {
    background-color: ${colors.White};
    border-color: ${colors.White};
    color: ${colors.BlueNight};
  }
`;

export const FooterLinkText = styled.span`
  display: block;
  margin-right: 10px;
`;

const sharedStyles = () => css`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-family: ${({ theme }) => getFont(theme, 'semiBold')};
  text-decoration: none;
  cursor: pointer;
  color: ${colors.BlueMoon};
  &:hover {
    color: ${colors.BlueDark};
  }
  svg,
  svg path {
    fill: ${colors.BlueMoon};
  }
  &:hover svg,
  &:hover svg path {
    fill: ${colors.BlueDark};
  }
`;

export const NavigationFooterLinkWrapperAsLink = styled(Link)`
  ${sharedStyles}
`;
export const NavigationFooterLinkWrapperAsGLink = styled(GatsbyLink)`
  ${sharedStyles}
`;

const ExternalLink: React.FC<Props> = ({
  url,
  image,
  linkStyle,
  children,
  fullWidth,
  margin,
  locale,
  size,
  eventTracking,
}) => {
  const linkProps = {};
  if (eventTracking && eventTracking.category && eventTracking.action) {
    linkProps.onClick = (e) => callbackFn(e, eventTracking, url);
  }

  switch (linkStyle) {
    case LinkStyle.PRIMARY:
      return (
        <Button
          size='lg'
          href={url}
          targetBlank
          fullWidth={fullWidth}
          margin={margin}
          {...linkProps}
        >
          <CurrencyFormatter>{children}</CurrencyFormatter>
        </Button>
      );
    case LinkStyle.WHITE:
      return (
        <WhiteButton
          size='lg'
          href={url}
          targetBlank
          fullWidth={fullWidth}
          margin={margin}
          {...linkProps}
        >
          <CurrencyFormatter>{children}</CurrencyFormatter>
        </WhiteButton>
      );
    case LinkStyle.SECONDARY:
      return (
        <Link
          size='lg'
          href={url}
          iconRight={<Icon icon='icn_longArrowRight' />}
          isSemiBold
          targetBlank
          {...linkProps}
        >
          <CurrencyFormatter>{children}</CurrencyFormatter>
        </Link>
      );
    case LinkStyle.IMAGE:
      return (
        <a href={url} target={_BLANK} rel='noopener noreferrer' {...linkProps}>
          <Image {...image} />
        </a>
      );
    case LinkStyle.CONTENT:
      return (
        <ContentLink
          href={url}
          target={_BLANK}
          rel='noopener noreferrer'
          {...linkProps}
        >
          {children}
        </ContentLink>
      );
    case LinkStyle.INLINE:
      if (locale && url.includes('#cookie-preferences')) {
        const onPreferecesCenterClick = () => {
          if (typeof window !== 'undefined') {
            window['OneTrust'].ToggleInfoDisplay();
          }
        };

        return (
          <Link
            onClick={(e) => onPreferecesCenterClick(e)}
            size='inherit'
            underline
          >
            {children}
          </Link>
        );
      }

      return (
        <Link
          href={url}
          size={size ? size : 'inherit'}
          underline
          targetBlank
          {...linkProps}
        >
          {children}
        </Link>
      );

    case LinkStyle.FOOTERLINK:
      return (
        <NavigationFooterLinkWrapperAsLink
          href={url}
          size='inherit'
          targetBlank
          {...linkProps}
        >
          <FooterLinkText>{children}</FooterLinkText>
          <Icon
            icon='icn_longArrowRight'
            color={colors.BlueMoon}
            size='1.6rem'
          />
        </NavigationFooterLinkWrapperAsLink>
      );
    default:
      return (
        <Link href={url} targetBlank {...linkProps}>
          <CurrencyFormatter>{children}</CurrencyFormatter>
        </Link>
      );
  }
};

export default ExternalLink;
