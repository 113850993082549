import React from 'react';
import { graphql } from 'gatsby';
import { Badge as SCBadge } from '@keytrade/components-badge';

export type BadgeType = {
  title?: string;
  type?: string;
  margin?: string;
  size?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

type Props = BadgeType;

const Badge: React.FC<Props> = (data) => {
  return (
    <SCBadge
      size={data.size}
      onClick={data.onClick}
      margin={data.margin}
      kind={data.type}
    >
      {data.title}
    </SCBadge>
  );
};

export default Badge;
export const badgeQuery = graphql`
  fragment BadgeFragment on ContentfulBadge {
    title
    type
  }
`;
export const badgeRichTextQuery = graphql`
  fragment BadgeRichTextFragment on ContentfulBadge {
    contentful_id
    __typename
    ...BadgeFragment
  }
`;
