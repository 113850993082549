import React from 'react';
import styled from 'styled-components';
import { Button } from '@keytrade/components-button';
import { config } from '@keytrade/functions';

import { mediaQuery } from '@/utils/helpers';
import { LOGIN_ID } from '@/components/shared/htmlIds.contants';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

type styleProp = {
  dark: boolean;
};

const LoginButtonWrapper = styled(Button)<styleProp>`
  display: inline-flex;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 6px;
  background-color: ${colors.BlueSky};
  color: ${colors.White};
  padding: 0.8rem 1.2rem;
  font-size: 1.8rem;
  line-height: 24px;
  font-family: ${({ theme }) => getFont(theme, 'bold')};
  height: 44px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid ${colors.White};
  transition: all 0.2s ease-in-out;

  ${mediaQuery.lg(`
    border: 2px solid ${colors.BlueSky};
    margin-left: 12px;
    font-size: 1.6rem;
    line-height: 1.5;
    width: auto;
    margin-bottom: 0;
  `)}

  &:hover {
    background-color: ${colors.BlueDawn};
    border-color: ${colors.BlueDawn};
  }

  ${({ dark }) =>
    dark
      ? `
      border: none;
    `
      : `
      ${mediaQuery.lg(`
      border: 2px solid ${colors.BlueSky};
  `)};
`}
`;
export type Props = {
  buttonText: string;
  openLogin: any;
  dark: boolean;
};

const LoginButton: React.FC<Props> = ({ buttonText, openLogin, dark }) => {
  return (
    <LoginButtonWrapper
      id={LOGIN_ID}
      dark={dark}
      onClick={openLogin}
      role='dialog'
      aria-modal='false'
    >
      {buttonText}
    </LoginButtonWrapper>
  );
};

export default LoginButton;
