import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { decode as base64_decode } from 'base-64';
import { useCookies } from 'react-cookie';
import { useLocalization } from '@plugins/keytrade-localization/useLocalization';

import { LOGIN_COOKIE } from '@/utils/constants';
import DesktopLoginPanel from '@/components/navigation/DesktopLoginPanel';
import MobileLoginPanel from '@/components/navigation/MobileLoginPanel';
import { LoginData } from '@/components/navigation/Navigation';
import { getKeytradePublicPHPURL } from '@/utils/URLUtils';
import useTranslations from '@/hooks/useTranslations';

export type LoginPanelData = {
  loginData: LoginData;
  loginButtonRef: any;
  desktop: boolean;
  isOpen: boolean;
  closeModal: any;
  formRef: any;
  publicAuthURL: string;
};

export type Props = LoginPanelData;

export type LoginOAuthData = {
  success: boolean;
  redirectUrl: string;
  errorMessage: string;
  transactionId: number;
};

const OLD_PHP_LOGIN_ORIGINS = [
  'https://www.stage.w.keytradebank.be',
  'https://www.stage.x.keytradebank.be',
];

const KeytIdRegExp = new RegExp(/^(keyt)(\d+)$/, 'gi'); // 'keyt####' pattern

export const activateOldPHPLoginFlowForOrigin = (origin: string) =>
  OLD_PHP_LOGIN_ORIGINS.includes(origin);

const LoginPanel: React.FC<Props> = ({
  loginData,
  desktop,
  loginButtonRef,
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslations();
  const { locale } = useLocalization();
  const [cookies] = useCookies([LOGIN_COOKIE]);
  const [username, setUsername] = useState('');
  const [formOptions, setFormOptions] = useState({});
  const [isChecked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const authPath = t('auth.path');
  const publicPHPURL = getKeytradePublicPHPURL();
  const publicAuthURL = `${publicPHPURL}/${authPath}`;
  const ORIGIN_WEB = 1;
  const SCOPE = 'Login';

  const formRef = useRef<any>();

  useEffect(() => {
    if (cookies.KEYLoginMethod) {
      const decoded_cookie = base64_decode(cookies.KEYLoginMethod);
      const cookie_object = JSON.parse(decoded_cookie);
      if (cookie_object && cookie_object.remember && cookie_object.value) {
        setUsername(cookie_object.value);
      }
      setChecked(cookie_object?.remember ?? false);
    }
  }, [cookies]);

  const handleUsername = (logon) => {
    const isMatching = logon.match(KeytIdRegExp);
    setUsername(isMatching ? logon.toUpperCase() : logon);
  };

  const handlePhpLogin = async () => {
    const backendURL = getKeytradePublicPHPURL();
    const sessionURL = `${backendURL}/remoteapp/p/ws/rest/0.2/user/session.json`;
    const rawSessionResponse = await fetch(sessionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login: username,
        lang: locale,
      }),
    });
    const sessionResponse = await rawSessionResponse.json();

    if (sessionResponse.transactionMonitorID > 0) {
      setFormOptions({
        lang: locale,
        branch: sessionResponse.branch,
      });

      formRef.current.action = `${formRef.current.action}?tm=${sessionResponse.transactionMonitorID}`;
      formRef.current.submit();
    } else {
      setErrorMessage(t('login.genericErrorMessage'));
    }
  };

  const handleOAuthLogin = async () => {
    const backendURL = getKeytradePublicPHPURL();
    const sessionURL = `${backendURL}/node/backend/cldprxy/access-mgmt/am/begin/login`;

    const rawSessionResponse = await fetch(sessionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login: username,
        routinKey: SCOPE,
        scope: SCOPE,
        origin: ORIGIN_WEB,
        lang: locale,
      }),
    });

    const res: LoginOAuthData = await rawSessionResponse.json();

    if (res.success) {
      setFormOptions({ lang: locale });
      formRef.current.action = res.redirectUrl;
      formRef.current.submit();
    } else {
      setErrorMessage(t('login.genericErrorMessage'));
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      if (username.length === 0) {
        setErrorMessage(t('login.emptyUsername'));
        return;
      }
      if (
        typeof window !== 'undefined' &&
        activateOldPHPLoginFlowForOrigin(window.location.origin)
      ) {
        await handlePhpLogin();
      } else {
        await handleOAuthLogin();
      }
    } catch (error) {
      setErrorMessage(t('login.genericErrorMessage'));
    }
  };
  return (
    <ThemeProvider theme={{ dark: false }}>
      {desktop ? (
        <DesktopLoginPanel
          loginButtonRef={loginButtonRef}
          loginData={loginData}
          handleLogin={(e) => handleLogin(e)}
          input={username}
          isChecked={isChecked}
          setInput={handleUsername}
          setChecked={setChecked}
          closeModal={closeModal}
          formRef={formRef}
          formOptions={formOptions}
          publicAuthURL={publicAuthURL}
          errorMessage={errorMessage}
        />
      ) : (
        <MobileLoginPanel
          loginData={loginData}
          handleLogin={(e) => handleLogin(e)}
          input={username}
          isChecked={isChecked}
          setInput={handleUsername}
          setChecked={setChecked}
          isOpen={isOpen}
          closeModal={closeModal}
          formRef={formRef}
          formOptions={formOptions}
          publicAuthURL={publicAuthURL}
          errorMessage={errorMessage}
        />
      )}
    </ThemeProvider>
  );
};

export default LoginPanel;
