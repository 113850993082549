import React from 'react';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import { Icon } from '@keytrade/components-icon';
import { InputText } from '@keytrade/components-inputtext';
import { Checkbox } from '@keytrade/components-checkedinput';
import { Button } from '@keytrade/components-button';
import { Link as SCLink } from '@keytrade/components-link';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';

import { generateSlug } from '@/utils/SlugUtils';
import { LoginData } from '@/components/navigation/Navigation';
import useTranslations from '@/hooks/useTranslations';
import { mediaQuery } from '@/utils/helpers';

const { list: colors } = config.colors;

const PanelContent = styled.div`
  padding: 20px 32px;
`;

const SubLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  font-size: 1.6rem;
`;

const LoginButtonWrapper = styled.div`
  margin: 31px 0 0 0;
`;

const Link = styled(SCLink)`
  & + & {
    margin-top: 0.8rem;
  }
`;

const LinkHiddenMobile = styled(Link)`
  display: none;

  ${mediaQuery.lg(`
    display: inline-flex;
  `)};
`;

const SubLinks = styled.div`
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export type LoginPanelData = {
  loginData: LoginData;
  handleLogin: any;
  input: string;
  isChecked: boolean;
  setInput: any;
  setChecked: any;
  formRef: any;
  formOptions: any;
  publicAuthURL: string;
  errorMessage: string;
};

export type Props = LoginPanelData;

const LoginPanelForm: React.FC<Props> = ({
  loginData,
  handleLogin,
  isChecked,
  input,
  setChecked,
  setInput,
  formRef,
  formOptions,
  publicAuthURL,
  errorMessage,
}) => {
  const { t } = useTranslations('login');

  return (
    <>
      <PanelContent>
        <form
          action={publicAuthURL}
          method='post'
          onSubmit={handleLogin}
          ref={formRef}
        >
          <InputText
            label={loginData.clientNumberLabel}
            labelWeight='semiBold'
            name='USERNAME'
            error={errorMessage.length !== 0}
            errorMessage={errorMessage}
            value={input}
            placeholder={t('inputfieldPlaceholder')}
            onChange={(e) => setInput(e.target.value)}
            autoFocus
          />
          <Checkbox
            checked={isChecked}
            labelWeight='regular'
            onChange={() => setChecked(!isChecked)}
            value={+Boolean(isChecked)}
            name='remember-me'
            margin='15px 0 15px 0 '
          >
            {loginData.rememberMeLabel}
          </Checkbox>
          <LoginButtonWrapper>
            <Button size='lg' fullWidth onClick={handleLogin}>
              {loginData.loginButtonCopy}
            </Button>
          </LoginButtonWrapper>
          <input name='lang' type='hidden' value={formOptions?.lang} />
          <input name='branch' type='hidden' value={formOptions?.branch} />
          <input name='method' type='hidden' value='username' />
        </form>
        <SubLinksContainer>
          <SubLinks>
            <Link
              size='lg'
              color='GreyDark'
              render={() => (
                <GatsbyLink to={generateSlug(loginData.forgotLogin.page)}>
                  {loginData.forgotLogin.linkText}
                  <Icon
                    icon='icn_longArrowRight'
                    color={colors.GreyDark}
                    margin={'0 0  0 10px'}
                  />
                </GatsbyLink>
              )}
            />
            {loginData?.softkeyRecovery && (
              <LinkHiddenMobile
                size='lg'
                color='GreyDark'
                render={() => (
                  <GatsbyLink to={generateSlug(loginData.softkeyRecovery.page)}>
                    {loginData.softkeyRecovery.linkText}
                    <Icon
                      icon='icn_longArrowRight'
                      color={colors.GreyDark}
                      margin={'0 0  0 10px'}
                    />
                  </GatsbyLink>
                )}
              />
            )}
          </SubLinks>
        </SubLinksContainer>
      </PanelContent>
    </>
  );
};

export default LoginPanelForm;
