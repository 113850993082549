import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { config } from '@keytrade/functions';

const { getFont } = config.fonts;
const { list: colors } = config.colors;

export type ColoredTextType = {
  title: string;
  color: string;
};

const getTextColor = (color: string): string => {
  switch (color) {
    case 'Green':
      return colors.GreenSeafoam;
    case 'Red':
      return colors.TomatoFlash;
    case 'Blue':
      return colors.BlueSky;
  }
};

type Props = ColoredTextType;

type ColoredSpanProps = {
  color: string;
};
const ColoredSpan = styled.span<ColoredSpanProps>`
  ${({ color }) => `color: ${color};`};
  font-family: ${({ theme }) => getFont(theme, 'semiBold')};
`;
const ColoredText: React.FC<Props> = (data) => {
  return (
    <ColoredSpan color={getTextColor(data.color)}>{data.title}</ColoredSpan>
  );
};

export default ColoredText;
export const coloredTextQuery = graphql`
  fragment ColoredTextRichTextFragment on ContentfulColoredText {
    contentful_id
    __typename
    title
    color
  }
`;
