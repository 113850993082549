import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';

const { list: colors } = config.colors;

export type FootnoteType = {
  text: { text: string };
  isBold: boolean;
  color: string;
};

type Props = FootnoteType;

const FootnoteBlock = styled.div<Props>`
  color: ${(props) => colors[props.color]}};
  font-size: 1.4rem;
  font-family: ${({ isBold }) => (isBold ? 'Averta SemiBold' : 'Averta')};
  line-height: 1.5;
`;

const Footnote: React.FC<Props> = ({ text, isBold, color }) => {
  return (
    <FootnoteBlock isBold={isBold} color={color}>
      {text && <CurrencyFormatter>{text?.text}</CurrencyFormatter>}
    </FootnoteBlock>
  );
};

export default Footnote;

export const footnoteQuery = graphql`
  fragment FootnoteRichTextFragment on ContentfulFootnote {
    contentful_id
    __typename
    isBold
    text {
      text
    }
  }
`;
