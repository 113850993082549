import { useContext } from 'react';

import {
  PageCustomizationContext,
  ContextType,
} from '@/context/PageCustomizationContext';

export const usePageCustomizationContext = (): ContextType => {
  const context = useContext(PageCustomizationContext);

  if (!context) {
    throw new Error(
      'usePageCustomizationContext must be used within a PageCustomizationProvider',
    );
  }

  return context;
};
