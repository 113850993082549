import React from 'react';
import styled from 'styled-components';

import { PageInfoType } from '@/components/Page';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';
import { generateSlug } from '@/utils/SlugUtils';
import { config } from '@keytrade/functions';
import { Icon } from '@keytrade/components-icon';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

export type Props = {
  __typename: string;
  page?: PageInfoType;
  url?: string;
  linkText?: string;
  prefix?: string;
};

const SubCategoryLinkWrapper = styled(GatsbyLink)`
  display: inline-flex;
  align-items: center;
  color: ${colors.GreyBlue};
  text-decoration: none;
  cursor: pointer;
`;

const LinkText = styled.span`
  display: block;
  margin-right: 10px;
  font-family: ${({ theme }) => getFont(theme, 'semiBold')};
  color: ${colors.BlueDark};
`;
const LinkPrefix = styled.span`
  display: block;
  margin-right: 5px;
`;

const SubCategoryLink: React.FC<Props> = ({ page, linkText, prefix }) => {
  return (
    <SubCategoryLinkWrapper to={generateSlug(page)}>
      <LinkPrefix>{prefix}</LinkPrefix>
      <LinkText>{linkText}</LinkText>
      <Icon icon='icn_longArrowRight' color={colors.BlueMoon} size='1.6rem' />
    </SubCategoryLinkWrapper>
  );
};

export default SubCategoryLink;
