import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export type ImageType = {
  title?: string;
  transparent?: boolean;
  gatsbyImageData?: IGatsbyImageData;
  file: {
    url: string;
    contentType?: string;
    details?: {
      image?: {
        width: number;
        height: number;
      };
    };
  };
};

type Props = ImageType & {
  className?: string;
};

const SvgImage = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

const Img = styled.img`
  max-width: 100%;
`;

const Image: React.FC<Props> = ({
  gatsbyImageData,
  transparent,
  file,
  title,
  className,
}) => {
  if (!gatsbyImageData && !file) return <></>;
  if (!gatsbyImageData && file.contentType === 'image/svg+xml')
    return <SvgImage src={file.url} alt={title} className={className} />;
  if (!gatsbyImageData)
    return <Img src={file.url} alt={title} className={className} />;
  if (transparent) {
    gatsbyImageData.backgroundColor = 'transparent';
  }
  return (
    <GatsbyImage image={gatsbyImageData} alt={title} className={className} />
  );
};

export default Image;

export const linkQuery = graphql`
  fragment ImageFragment on ContentfulAsset {
    title
    file {
      contentType
      url
    }
  }
`;
