import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { mediaQuery } from '@/utils/helpers';
import { PageInfoType } from '@/components/Page';
import { generateSlug } from '@/utils/SlugUtils';
import { Icon } from '@keytrade/components-icon';
import { config } from '@keytrade/functions';
import { useLocalization } from '@plugins/keytrade-localization/useLocalization';
import useLocalStorage from '@/hooks/useLocalStorage';
import { LANGUAGE_STORAGE_KEY } from '@/utils/constants';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

const DropDownContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
  ${mediaQuery.lg(`
    width: auto;
  `)}
`;
const DropDownContainerWrapper = styled.div`
  width: 100%;
`;

const DropDownHeader = styled.a`
  position: relative;
  width: 100%;
  padding-left: 60px;
  text-decoration: none;
  display: flex;
  align-items: center;

  ${mediaQuery.lg(`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 0;
    padding: 12px 8px;
  `)}

  ${({ theme }) =>
    theme.dark
      ? ` 
      ${mediaQuery.lg(`
          &:hover, :focus {
            background-color: ${colors.BlueMoon};
          } 
      `)}
    `
      : `
      ${mediaQuery.lg(`
        &:hover, :focus {
          background-color: ${colors.BlueDark};
        }
      `)};     
  `}
`;

const DropDownHeaderTitle = styled.span`
  position: relative;
  font-size: 1.6rem;
  font-family: ${({ theme }) => getFont(theme, 'semiBold')};
  color: ${colors.BlueDark};
  ${mediaQuery.lg(`
    display: inline-block;
    padding-right: 13px;
    font-size: 1.3rem;
    color: ${colors.BlueLight};
    cursor: pointer;
  `)}

  &:before {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
type TabletAndActiveProps = ActiveProps & {
  isTablet: boolean;
};
const DropDownListContainer = styled.div<TabletAndActiveProps>`
  ${({ isTablet, active }) =>
    isTablet &&
    `
    overflow:hidden;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  ${
    active
      ? `
  max-height: 20rem;
  `
      : `
  max-height: 0;
  margin:0;
  `
  }
  `}

  ${mediaQuery.lg(` 
  position: absolute;
  right: 0;
  margin-top: 8px;
  z-index: 100;
  background-color: ${colors.White};
  border-radius: 12px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03),
    0px 14px 28px 0px rgba(0, 0, 0, 0.1);
    `)}
`;

const DropDownList = styled.ul`
  padding: 10px 0;
  margin: 20px 0;
  background-color: ${colors.BlueLight};
  ${mediaQuery.lg(`
    background-color: transparent;
    padding: auto;
    margin: auto;
  `)}
`;

const IconActiveContainer = styled.span`
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -1.2rem;
`;

const ListItem = styled.li`
  position: relative;
  list-style-type: none;

  > a {
    display: flex;
    align-items: center;
    height: 40px;
    width: 264px;
    padding-left: 56px;
    text-decoration: none;
    color: ${colors.BlueDark};
    font-size: 1.6rem;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: ${colors.BlueLight};
    }
  }
  ${mediaQuery.lg(`
    background-color: ${colors.White};
  `)}
`;

const LanguageIcon = styled.span`
  position: absolute;
  left: 30px;
  top: 2px;
  margin-left: -10px;
  ${mediaQuery.lg(`
    display: none;
  `)}
`;
type ActiveProps = {
  active: boolean;
};
export type Props = LanguageData;

export type LanguageData = {
  languageSlugs: Array<LanguageSlug>;
  isScrolled: boolean;
  isTablet: boolean;
};

export type LanguageSlug = PageInfoType & {
  slugPrefix: string;
  ignoreParent?: boolean;
};

const languageName = {
  en: 'English',
  nl: 'Nederlands',
  fr: 'Français',
};
const ArrowIcon = styled(Icon)<ActiveProps>`
  transition: transform 0.2s ease;
  ${({ active }) =>
    active
      ? `
  transform:scaleY(-1) translateY(0.2rem);
`
      : `
      transform:scaleY(1);
`};
`;

const LanguageSwitch: React.FC<Props> = ({
  languageSlugs,
  isScrolled,
  isTablet,
}) => {
  const { navigate, locale, allLang } = useLocalization();
  const [, setStoredLanguage] = useLocalStorage(LANGUAGE_STORAGE_KEY, locale);
  const [isOpen, setIsOpen] = useState(false);
  const openLanguageDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const language = locale;
  const languageSelectorRef = useRef<any>();

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleClose);
    return () => {
      window.removeEventListener('scroll', handleClose);
    };
  });
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isOpen &&
        languageSelectorRef.current &&
        !languageSelectorRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <DropDownContainer ref={languageSelectorRef}>
      <DropDownContainerWrapper>
        <DropDownHeader href='' onClick={openLanguageDropdown}>
          <LanguageIcon>
            <ArrowIcon
              icon='icn_arrowDown'
              active={isOpen}
              color={colors.BlueDark}
              size='2rem'
            />
          </LanguageIcon>
          <DropDownHeaderTitle>{languageName[language]}</DropDownHeaderTitle>
          {isOpen ? (
            <Icon icon='icn_cross' color={colors.BlueLight} size='1rem' />
          ) : (
            <Icon icon='icn_arrowDown' color={colors.BlueLight} size='1rem' />
          )}
        </DropDownHeader>
        {(isOpen || (!isOpen && isTablet)) && !isScrolled && (
          <DropDownListContainer active={isOpen} isTablet={isTablet}>
            <DropDownList>
              {allLang.map((languageCode) => {
                const matchingLanguageSlug = languageSlugs.find(
                  (languageSlug) => languageSlug.node_locale === languageCode,
                );
                if (!matchingLanguageSlug) {
                  return;
                }

                const url = matchingLanguageSlug.ignoreParent
                  ? matchingLanguageSlug.slugPrefix +
                    '/' +
                    matchingLanguageSlug.slug
                  : generateSlug(
                      matchingLanguageSlug,
                      matchingLanguageSlug.slugPrefix,
                    );
                return (
                  <ListItem key={languageCode}>
                    <IconActiveContainer>
                      {locale === languageCode && (
                        <Icon icon='icn_check' color={colors.BlueSky} />
                      )}
                    </IconActiveContainer>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setStoredLanguage(languageCode);

                        if (
                          typeof window !== 'undefined' &&
                          typeof window['OnetrustActiveGroups'] === 'string'
                        ) {
                          window['OneTrust'].changeLanguage(languageCode);
                        }

                        navigate(url, languageCode);
                      }}
                      href={`/${languageCode}${url}`}
                    >
                      {languageName[languageCode]}
                    </a>
                  </ListItem>
                );
              })}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainerWrapper>
    </DropDownContainer>
  );
};

export default LanguageSwitch;
