import {
  DefaultPaths,
  PageInfoType,
  PageTypeInfoType,
  Path,
} from '@/components/Page';
import { LanguageSlug } from '@/components/navigation/LanguageSwitch';

const generateSlug = (page: PageInfoType, prefix: string = null): string => {
  let slug = '';

  if (page) {
    if (page.__typename === 'ContentfulHomepage') {
      return '/';
    }

    const newSlug = (page.slug ? '/' + page.slug : '').replace('//', '/');

    slug = page.parentPage
      ? `${generateSlug(page.parentPage, prefix)}${newSlug}`
      : `${prefix ? prefix : ''}${newSlug}`;
  }

  return slug;
};
const generatePageTypeSlug = (
  page: PageTypeInfoType,
  defaultPaths: DefaultPaths,
): string => {
  switch (page?.pageType?.__typename) {
    case 'ContentfulArticle':
      return defaultPaths?.blogPath + '/' + page.slug;
    case 'ContentfulSupportArticle':
      return defaultPaths?.supportPath + '/' + page.slug;
    default:
      return generateSlug(page);
  }
};

const addPrefixToLanguageSlugs = (
  languageSlugs: Array<LanguageSlug>,
  prefixPages: Array<PageInfoType>,
): void => {
  const hasAncestor = (page: PageInfoType, parent: PageInfoType): boolean => {
    if (page.contentful_id === parent.contentful_id) {
      return true;
    }

    if (page.parentPage) {
      return hasAncestor(page.parentPage, parent);
    }

    return false;
  };

  languageSlugs.forEach((languageSlug) => {
    const correctPrefixPage = prefixPages.find(
      (page) => page.node_locale === languageSlug.node_locale,
    );
    if (hasAncestor(languageSlug, correctPrefixPage)) {
      return;
    }
    languageSlug.slugPrefix = generateSlug(correctPrefixPage);
    languageSlug.ignoreParent = true;
  });
};

const generateInternalUrl = (page: PageInfoType): string => {
  if (!page) return '/';
  return `/${page.node_locale}${generateSlug(page)}`;
};

const generateAlternates = (pages: Array<PageInfoType>): Array<Path> => {
  const pathnames = new Array<Path>();
  pages.forEach((page) => {
    pathnames.push({ lang: page.node_locale, url: generateInternalUrl(page) });
  });
  return pathnames;
};

const getFixedSlugs = (slug: string): Array<any> => {
  return [
    {
      node_locale: 'nl',
      slug: slug,
    },
    {
      node_locale: 'en',
      slug: slug,
    },
    {
      node_locale: 'fr',
      slug: slug,
    },
  ];
};

const ensurePrefixedSlug = (slug: string): string => {
  if (!slug) {
    return;
  }

  if (slug === '/') {
    return slug;
  }
  return `/${slug}`;
};

export {
  addPrefixToLanguageSlugs,
  generateSlug,
  generateAlternates,
  generateInternalUrl,
  getFixedSlugs,
  ensurePrefixedSlug,
  generatePageTypeSlug,
};
