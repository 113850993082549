/**
 * @note https://confluence.keytrade.com/display/CHAN/Selligent+banners
 * @jira https://jira.keytrade.com/browse/CHAN-5583
 */
import React, { useEffect, useRef, useState } from 'react';

import { COOKIE_CATEGORIES } from '@/utils/constants';
import { track } from '@/utils/selligentHelpers';
import { useCookieConsent } from '@/hooks/useCookieConsent';

import RenderOnClientHOC from '../shared/RenderOnClientHOC';

const TRACK_INIT_DELAY = 150;
const DEFAULT_TIMEOUT = 3000;

const SelligentWatcher: React.FC = () => {
  // @TODO: remove this once we're fully using OneTrust
  const [selligentUserData, setSelligentUserData] = useState();
  const { consentActiveGroups, hasConsentFor } = useCookieConsent();

  /**
   * setTimeout refs.
   */
  const defaultTimerRef = useRef(null);
  const trackInitTimerRef = useRef(null);

  /**
   * Clear the setTimeout refs to cancel the fallback behaviour.
   */
  const clearTimers = () => {
    clearTimeout(defaultTimerRef.current);
    clearTimeout(trackInitTimerRef.current);
  };

  /**
   * Execute other components that hooked into this SelligentWatcher.
   * Data can be real Selligent data or null if we want to have components show their fallback behaviour.
   * @param data Selligent data or null
   */
  const runCallbacks = (data) => {
    if (
      typeof window !== 'undefined' &&
      window.hasOwnProperty('bt_trackingFinishedCallbacks')
    ) {
      window['bt_trackingFinishedCallbacks'].forEach((cb) => {
        cb(data);
      });
    }
  };

  // register the hooks selligent needs to call when selligent /track endpoint returns a response.
  if (typeof window !== 'undefined') {
    window['bt_trackingFinishedCallback'] = setSelligentUserData;

    // clear previous list of finished callbacks
    if (!selligentUserData) {
      window['bt_trackingFinishedCallbacks'] = [];
    }
  }

  /**
   * On page load...
   */
  useEffect(() => {
    // if we didn't get selligent data when we reach our DEFAULT_TIMEOUT then we execute fallback behaviour
    // fallback options should be triggered in other components with the null value
    defaultTimerRef.current = setTimeout(() => {
      runCallbacks(null);
    }, DEFAULT_TIMEOUT);

    // we need to delay the launch of the selligent /track endpoint call because we need to be sure some global vars are set
    // they're set in the SEO.tsx component where the selligent script is injected with.
    trackInitTimerRef.current = setTimeout(() => {
      // only enable selligent when the end-user gave consent (opt-in)
      let doSelligentTrack = false;

      if (hasConsentFor(COOKIE_CATEGORIES.ANALYTIC)) {
        doSelligentTrack = track(
          window['SELLIGENT_MAINTAG'],
          window['SELLIGENT_LANGUAGE'],
        );
      }

      // if no consent was given by the end-user, we can abort the timers and push for the fallback behaviour
      if (!doSelligentTrack) {
        clearTimers();
        runCallbacks(null);
      }
    }, TRACK_INIT_DELAY);

    return () => {
      clearTimers();
    };
  }, [consentActiveGroups]);

  /**
   * When we receive data from selligent...
   */
  useEffect(() => {
    if (typeof selligentUserData !== 'undefined') {
      clearTimers();
      runCallbacks(selligentUserData);
    }
  }, [selligentUserData]);

  return null;
};

export default RenderOnClientHOC(SelligentWatcher);
