import {
  youtubeRegExp,
  getVideoUrlForYoutube,
  getYoutubeThumbnailFromVideoUrl,
} from './Youtube.utils';
import {
  vimeoRegExp,
  getVideoUrlForVimeo,
  getVimeoThumbnailFromVideoUrl,
} from './Vimeo.utils';

export const videoPlatforms = {
  youtube: 'youtube',
  vimeo: 'vimeo',
} as const;

const videoPlatformsMapper = {
  [videoPlatforms.youtube]: {
    regexp: youtubeRegExp,
    url: getVideoUrlForYoutube,
    thumbnail: getYoutubeThumbnailFromVideoUrl,
  },
  [videoPlatforms.vimeo]: {
    regexp: vimeoRegExp,
    url: getVideoUrlForVimeo,
    thumbnail: getVimeoThumbnailFromVideoUrl,
  },
} as const;

type VideoPlatform = keyof typeof videoPlatforms;

export const getVideoPlatform = (url: string): VideoPlatform | null => {
  for (const [platform, { regexp }] of Object.entries(videoPlatformsMapper)) {
    if (regexp.test(url)) {
      return platform as VideoPlatform;
    }
  }
  return null;
};

export const getVideoPlayerUrl = (
  url: string,
  origin: string,
): string | null => {
  const videoPlatform = getVideoPlatform(url);

  if (!videoPlatform) {
    return null;
  }

  const getVideoUrl = videoPlatformsMapper[videoPlatform]?.url;
  return getVideoUrl(url, origin);
};

export const getVideoThumbnailUrl = (url: string): string | null => {
  const videoPlatform = getVideoPlatform(url);

  if (!videoPlatform) {
    return null;
  }

  const getVideoThumbnail = videoPlatformsMapper[videoPlatform].thumbnail;
  return getVideoThumbnail(url);
};
