import React, { useState } from 'react';
import styled from 'styled-components';
import { Span } from '@keytrade/components-span';
import { Icon } from '@keytrade/components-icon';
import { config } from '@keytrade/functions';

import {
  LoginData,
  NavigationCategories,
} from '@/components/navigation/Navigation';
import NavigationLink from '@/components/navigation/NavigationLink';
import Link, { LinkStyle, LinkType } from '@/components/Link';
import LanguageSwitch from '@/components/navigation/LanguageSwitch';
import LoginButton from '@/components/navigation/LoginButton';
import MobileCategory from '@/components/navigation/MobileCategory';
import LoginPanel from '@/components/navigation/LoginPanel';
import useTranslations from '@/hooks/useTranslations';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const BackgroundLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: all;
  transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1);
`;

const Panel = styled.div`
  position: absolute;
  left: calc(-100% + 3.6rem);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: calc(100% - 3.6rem);
  height: 100%;
  overflow: auto;
  background-color: white;
  z-index: 99;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08),
    0px 12px 20px 0px rgba(0, 0, 0, 0.14);
  transform: translateX(100%);
  transition: transform 0.2s, box-shadow 0.2s cubic-bezier(0.23, 1, 0.32, 1);
`;

const CategoryWrapper = styled.div``;

const IconContainer = styled.span`
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -10px;
  margin-left: -10px;
`;

const SearchContainer = styled.div`
  position: relative;
  padding-left: 0;
`;
const SearchButton = styled.button`
  position: relative;
  width: 100%;
  display: flex;
  padding: 2.4rem 0 2.4rem 6rem;
  font-size: 2rem;
  line-height: 2.4rem;
  font-family: ${({ theme }) => getFont(theme, 'bold')};
  color: ${colors.BlueDark};
  background-color: transparent;
  border: none;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1px;
    width: calc(100% - 60px);
    background-color: ${colors.BlueLight};
  }
`;

const UtilityNavigation = styled.div``;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6rem;
  margin-top: 1.2rem;
`;
const ContenWrapper = styled.div``;
const MobileButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 20px;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  top: 2.2rem;
  left: 2rem;
`;

const SearchInput = styled.input`
  padding: 2.6rem 4.2rem 2.6rem 6rem;
  border: none;
  font-family: ${({ theme }) => getFont(theme, 'regular')};
  border-bottom: 1px solid ${colors.BlueLight};
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${colors.BlueDark};
  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${colors.GreyBlue};
  }
`;

export type Props = MobileMenuData;

export type MobileMenuData = {
  languageSlugs: Array<any>;
  support: LinkType;
  contact: LinkType;
  blog: LinkType;
  navigationCategories: Array<NavigationCategories>;
  openAccountButton: LinkType;
  loginButton: string;
  loginData: LoginData;
  doSearch: (value: string) => void;
  isSearching: boolean;
  setIsSearching: (bool: boolean) => void;
  searchValue: string;
};

const MobileMenu: React.FC<Props> = ({
  languageSlugs,
  support,
  contact,
  blog,
  navigationCategories,
  openAccountButton,
  loginButton,
  loginData,
  doSearch,
  isSearching,
  setIsSearching,
  searchValue,
}) => {
  const [openIndex, setOpenIndex] = useState<number>();
  const [isMobileLoginModalOpen, setLoginModalOpen] = useState(false);
  const { t } = useTranslations('search');

  const toggleLoginModal = () => {
    setIsSearching(false);
    setLoginModalOpen(!isMobileLoginModalOpen);
  };

  return (
    <Overlay>
      <BackgroundLayer></BackgroundLayer>
      <Panel>
        {isSearching ? (
          <>
            <SearchIconContainer>
              <Icon icon='icn_search' size='2.8rem' color={colors.BlueSky} />
            </SearchIconContainer>
            <SearchInput
              autoFocus
              placeholder={t('placeholder')}
              onChange={(e) => doSearch(e.target.value)}
              value={searchValue}
            />
          </>
        ) : (
          <>
            <ContenWrapper>
              {navigationCategories && (
                <CategoryWrapper>
                  {React.Children.toArray(
                    navigationCategories.map((navCategory, i) => (
                      <MobileCategory
                        handleClick={() => {
                          setOpenIndex(i === openIndex ? null : i);
                        }}
                        category={navCategory}
                        key={i}
                        isOpen={openIndex === i}
                      />
                    )),
                  )}
                </CategoryWrapper>
              )}
              <SearchContainer>
                <SearchButton onClick={() => setIsSearching(true)}>
                  <IconContainer>
                    <Icon
                      icon='icn_search'
                      color={colors.BlueSky}
                      size='2.4rem'
                    />
                  </IconContainer>
                  <Span size='titleXxs' color='BlueDark' weight='semiBold'>
                    {t('navigation')}
                  </Span>
                </SearchButton>
              </SearchContainer>
              <UtilityNavigation>
                <LinkWrapper>
                  <NavigationLink {...blog} />
                  <NavigationLink {...support} />
                  <NavigationLink {...contact} />
                </LinkWrapper>
                <LanguageSwitch
                  languageSlugs={languageSlugs}
                  isScrolled={false}
                  isTablet={true}
                />
              </UtilityNavigation>
            </ContenWrapper>
            <MobileButtonWrapper>
              <LoginButton
                openLogin={toggleLoginModal}
                dark={false}
                buttonText={loginButton}
              />
              <Link {...openAccountButton} linkStyle={LinkStyle.PRIMARY} />
            </MobileButtonWrapper>
            <LoginPanel
              loginData={loginData}
              desktop={false}
              loginButtonRef={null}
              isOpen={isMobileLoginModalOpen}
              closeModal={toggleLoginModal}
            />
          </>
        )}
      </Panel>
    </Overlay>
  );
};

export default MobileMenu;
