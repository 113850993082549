import React from 'react';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import { Icon } from '@keytrade/components-icon';
import { IconButton } from '@keytrade/components-button';

import { LoginData } from '@/components/navigation/Navigation';
import LoginPanelForm from '@/components/navigation/LoginPanelForm';
import useTranslations from '@/hooks/useTranslations';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

const Panel = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: ${colors.White};
`;
const CloseModalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 20px;
  border-bottom: 1px solid ${colors.BlueLight};
`;
const CloseModalText = styled.span`
  display: inline-block;
  margin-left: 22px;
  font-size: 1.6rem;
  font-family: ${({ theme }) => getFont(theme, 'semiBold')};
`;

export type LoginPanelData = {
  loginData: LoginData;
  handleLogin: any;
  input: string;
  isChecked: boolean;
  setInput: any;
  setChecked: any;
  isOpen: boolean;
  closeModal: any;
  formRef: any;
  formOptions: any;
  publicAuthURL: string;
  errorMessage: string;
};

export type Props = LoginPanelData;

const MobileLoginPanel: React.FC<Props> = ({
  loginData,
  handleLogin,
  isChecked,
  input,
  setChecked,
  setInput,
  isOpen,
  closeModal,
  formRef,
  formOptions,
  publicAuthURL,
  errorMessage,
}) => {
  const { t } = useTranslations('login');

  return (
    <>
      {isOpen && (
        <Panel>
          <CloseModalButtonContainer>
            <IconButton
              icon={<Icon icon='icn_longArrowLeft' />}
              kind='secondary'
              onClick={closeModal}
              accessibilityText={t('closeLoginForm')}
            />
            <CloseModalText>{loginData.loginText}</CloseModalText>
          </CloseModalButtonContainer>
          <LoginPanelForm
            loginData={loginData}
            handleLogin={handleLogin}
            isChecked={isChecked}
            input={input}
            setChecked={setChecked}
            setInput={setInput}
            formRef={formRef}
            formOptions={formOptions}
            publicAuthURL={publicAuthURL}
            errorMessage={errorMessage}
          />
        </Panel>
      )}
    </>
  );
};

export default MobileLoginPanel;
