import { BREAKPOINTS } from './constants';

export const getLangFromLocale = (locale: string): string =>
  locale.split('-')[0];

// We expose only min-width here, intentionally as this should be the most common use-case and we want to limit mixing min-width and max-width if it can be avoided.
// It's fine to build your own max-width media queries based on the BREAKPOINTS constants though (or even custom breakpoints if needed), just remember to substract 1px to make sure they don't match multiple media queries
const constructMediaQuery = (breakpoint: number, css: string): string => `
  @media only screen and (min-width: ${breakpoint}px) {
    ${css}
  }
`;

const constructMediaQueryMax = (breakpoint: number, css: string): string => `
  @media only screen and (max-width: ${breakpoint - 1}px) {
    ${css}
  }
`;

export const mediaQuery = {
  sm: (css: string): string => constructMediaQuery(BREAKPOINTS.SM, css),
  md: (css: string): string => constructMediaQuery(BREAKPOINTS.MD, css),
  lg: (css: string): string => constructMediaQuery(BREAKPOINTS.LG, css),
  xl: (css: string): string => constructMediaQuery(BREAKPOINTS.XL, css),
};

export const mediaQueryMax = {
  sm: (css: string): string => constructMediaQueryMax(BREAKPOINTS.SM, css),
  md: (css: string): string => constructMediaQueryMax(BREAKPOINTS.MD, css),
  lg: (css: string): string => constructMediaQueryMax(BREAKPOINTS.LG, css),
  xl: (css: string): string => constructMediaQueryMax(BREAKPOINTS.XL, css),
};
