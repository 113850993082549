import { useContext } from 'react';

import {
  CookieConsentContext,
  CookieConsentContextType,
} from '@/context/CookieConsentContext';

export const useCookieConsent = (): CookieConsentContextType => {
  const context = useContext(CookieConsentContext);

  if (!context) {
    throw new Error(
      'useCookieConsent must be used within a CookieConsentProvider',
    );
  }

  return context;
};
