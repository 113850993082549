import React, { useRef } from 'react';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import { mediaQuery } from '@/utils/helpers';
import Richtext from '@/components/Richtext';
import Image from '@/components/Image';
import { Title } from '@keytrade/components-title';
import Paragraph from '@/components/Paragraph';
import { NavigationCategories } from '@/components/navigation/Navigation';
import { Icon } from '@keytrade/components-icon';
import Link, { LinkStyle } from '@/components/Link';
import SubCategoryLink from '@/components/navigation/SubCategoryLink';

import SubCategoryContainer from '@/components/navigation/SubCategoryContainer';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

const CategoryLink = styled.button`
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  ${({ theme }) =>
    theme.dark
      ? `
      ${mediaQuery.lg(`
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 74px;
        font-size: 1.6rem;
        padding: 0 30px;
        color: ${colors.White};
        text-decoration: none;
        font-family:  ${getFont(theme, 'semiBold')};
      `)}
    `
      : `
      ${mediaQuery.lg(`
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 74px;
        font-size: 1.6rem;
        padding: 0 30px;
        color: ${colors.BlueDark};
        text-decoration: none;
        font-family:  ${getFont(theme, 'semiBold')};
    `)}   
`}
`;

const SubCategoryImageContent = styled.div``;
const SubCategoryImage = styled(Image)`
  width: 100%;
  height: 148px;
  border-radius: 8px;
  isolation: isolate;
`;

const NavigationSubCategory = styled.div`
  width: 100%;
  margin-right: 38px;
  max-width: 222px;
  &:last-of-type {
    margin-right: 0;
  }
`;
const SubNavigationWrapper = styled.div`
  display: flex;
  wrap: nowrap;
  flex-direction: row;
  align-items: start;
`;

const SubCategoryInformation = styled.div`
  width: auto;
  text-align: left;
  padding: 20px 0 0 0;
`;
const IconContainer = styled.span`
  ${mediaQuery.lg(`
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -0.8rem;
  `)}
`;
const TitleLink = styled.div`
  text-align: left;
  margin-bottom: 32px;
`;

export type Props = {
  category: NavigationCategories;
  isOpen: boolean;
  handleClick: any;
  scrolled: boolean;
  handleClose: any;
  navigationRef: any;
  refs: any;
  dark: boolean;
};
const NavigationCategory: React.FC<Props> = ({
  category,
  handleClick,
  isOpen,
  scrolled,
  handleClose,
  refs,
  dark,
}) => {
  const navigationRef = useRef<any>();
  const richTextCustomRenderer = {
    Paragraph: function CustomParagraph(node, children) {
      return (
        <Paragraph size='sm' margin='0' color='BlueMoon'>
          {children}
        </Paragraph>
      );
    },
  };
  return (
    <>
      <CategoryLink
        role='button'
        ref={navigationRef}
        onClick={handleClick}
        tabIndex='0'
        aria-label={category?.title?.page?.slug}
        aria-pressed={isOpen}
        aria-expand={isOpen}
        aria-controls={category?.title?.page?.slug}
      >
        {category.category}
        <IconContainer>
          {isOpen ? (
            <Icon
              icon='icn_arrowUp'
              color={dark ? colors.White : colors.BlueDark}
              size='2rem'
            />
          ) : (
            <Icon
              icon='icn_arrowDown'
              color={dark ? colors.White : colors.BlueDark}
              size='2rem'
            />
          )}
        </IconContainer>
      </CategoryLink>
      {isOpen && (
        <SubCategoryContainer
          id={category?.title?.page?.slug}
          handleClose={handleClose}
          scrolled={scrolled}
          category={category}
          navigationRef={navigationRef}
          refs={refs}
          aria-modal={isOpen}
        >
          {category.title && (
            <TitleLink>
              <Paragraph>
                <SubCategoryLink
                  prefix={category.titlePrefix}
                  {...category.title}
                />
              </Paragraph>
            </TitleLink>
          )}
          <SubNavigationWrapper>
            {category.subCategories.map((navSubCategory, i) => (
              <NavigationSubCategory key={i}>
                <Link
                  {...navSubCategory.link}
                  linkStyle={LinkStyle.CONTENT}
                  onClick={handleClose}
                >
                  <SubCategoryImageContent>
                    <SubCategoryImage {...navSubCategory.image} />
                  </SubCategoryImageContent>
                  <SubCategoryInformation>
                    <Title level='h3' size='xs' margin={'0 0 5px 0'}>
                      {navSubCategory.title}
                    </Title>
                    <Richtext
                      data={navSubCategory.richText}
                      customComponents={richTextCustomRenderer}
                      options={{ textSize: 'xs' }}
                    />
                  </SubCategoryInformation>
                </Link>
              </NavigationSubCategory>
            ))}
          </SubNavigationWrapper>
        </SubCategoryContainer>
      )}
    </>
  );
};

export default NavigationCategory;
