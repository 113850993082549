import React, { ReactNode, isValidElement } from 'react';
import styled from 'styled-components';
import { Money } from '@keytrade/components-money';

const Text = styled.span`
  white-space: break-spaces;
`;

type Props = {
  recursive?: boolean;
};
export type CurrencyTextProps = {
  amount: string;
  currency: string;
};
const CurrencyText: React.FC<CurrencyTextProps> = ({ amount, currency }) => {
  const digits = amount.split('.').length === 2 ? 2 : 0;
  return (
    <Money
      digits={digits}
      amount={parseFloat(amount)}
      currency={currency}
      size='inherit'
      color='inherit'
      inline
    />
  );
};
type ProcessedTextProps = {
  children: ReactNode;
};
const ProcessedText: React.FC<ProcessedTextProps> = ({ children }) => {
  return <>{children}</>;
};
const CurrencyFormatter: React.FC<Props> = ({ children }) => {
  const removeThousandSign = (amount: string): string => {
    if (
      amount.indexOf('.') > -1 &&
      amount.indexOf(',') === -1 &&
      amount.lastIndexOf('.') < amount.length - 3
    )
      amount = amount.replaceAll('.', '');
    if (
      amount.indexOf(',') > -1 &&
      amount.indexOf('.') === -1 &&
      amount.lastIndexOf(',') < amount.length - 3
    )
      amount = amount.replaceAll(',', '');
    return amount;
  };
  const processText = (input: string) => {
    let result: RegExpExecArray = null;
    const output = [];

    const regex =
      /(\d+\s\d+\s\d+\s?|\d+\s\d+\s?|\d+[^\.\,]?\d?\s?|\d+[\,\d]+\.\d+?\s?|\d+[\.\d]+\,\d+?\s?|\d+[\s\d]+[\,\.]\d+?\s?|\d+\.?\,?\d+\.?\,?\d+?\s?|\d+\.?\,?\d+?\s?)(EUR|BGN|CZK|DKK|EEK|HUF|LVL|PLN|RON|SEK|GBP|NOK|ISK|CHF|LTL|USD)($|[^a-z])/gim;

    while ((result = regex.exec(input)) !== null) {
      const index = result.index;

      output.push(<Text>{input.substring(0, index)}</Text>);
      const originalAmountLength = result[1].length;
      let amount = result[1].trim();

      const currency = result[2].toUpperCase();

      amount = amount.replaceAll(' ', '');
      //remove sign if not as comma-separator
      amount = removeThousandSign(amount);

      //check which character is first: , or .
      if (amount.indexOf(',') > -1 && amount.indexOf(',') > -1) {
        if (amount.indexOf(',') < amount.indexOf('.'))
          amount = amount.replaceAll(',', '');
        else {
          amount = amount.replaceAll('.', '');
          amount = amount.replaceAll(',', '.');
        }
      } else amount = amount.replaceAll(',', '.');

      output.push(CurrencyText({ amount, currency }));

      const afterCurrency = input.substring(
        index + originalAmountLength + currency.length,
        input.length + 1,
      );

      input = afterCurrency && ' ' + afterCurrency;
      regex.lastIndex = 0;
    }
    output.push(input);
    return React.Children.toArray(output);
  };

  const processChildren = (children: ReactNode): any => {
    if (!children) return null;
    if (children instanceof Array) {
      return React.Children.toArray(
        children.map((child) =>
          ProcessedText({ children: processChildren(child) }),
        ),
      );
    } else {
      if (!isValidElement(children)) {
        return <>{processText(children.toString())}</>;
      } else if (typeof children.props.children === 'string') {
        return React.cloneElement(
          children,
          [],
          processText(children.props.children),
        );
      } else {
        return children;
      }
    }
  };

  return processChildren(children);
};

CurrencyFormatter.defaultProps = {
  recursive: false,
};
export default CurrencyFormatter;
