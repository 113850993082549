export const vimeoRegExp = /vimeo\.com\/(?:[a-zA-Z0-9\-]+\/)*(\d+)/;

// Unoffical way of getting it but Vimeo force API call to get this.
// This would make things more complicated as Vimeo thumbnails would be async
// while YouTube ones would be sync.
export const getVimeoThumbnailUrl = (id: string): string =>
  `https://vumbnail.com/${id}_640.jpg`;

// Important to set "dnt" (do not track) parameter to true
const getVimeoVideoPlayerUrl = (id: string): string =>
  `https://player.vimeo.com/video/${id}?dnt=1&autoplay=0&autopause=1&controls=1&pip=1&speed=1&title=1`;

export const getVimeoIdFromUrl = (url: string): string => {
  const result = url.match(vimeoRegExp);
  return result?.length ? result[1] : null;
};

export const getVimeoThumbnailFromVideoUrl = (url: string): string => {
  return getVimeoThumbnailUrl(getVimeoIdFromUrl(url));
};

export const getVideoUrlForVimeo = (url: string): string => {
  const videoId = getVimeoIdFromUrl(url);
  return getVimeoVideoPlayerUrl(videoId);
};
