import React from 'react';
import { graphql } from 'gatsby';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';
import styled from 'styled-components';
import { config } from '@keytrade/functions';

import { Link } from '@keytrade/components-link';
import { Button } from '@keytrade/components-button';
import { Icon } from '@keytrade/components-icon';
import { LinkStyle } from '@/components/Link';
import { EventTrackingType } from '../types/EventTrackingTypes';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';
import { callbackFn } from './Link';
import {
  FooterLinkText,
  NavigationFooterLinkWrapperAsGLink,
} from './ExternalLink';

const { list: colors } = config.colors;

type Props = {
  to: string;
  linkStyle?: LinkStyle;
  fullWidth?: boolean;
  promo?: boolean;
  inline?: boolean;
  margin?: string;
  state?: any;
  onClick?: any;
  id?: string;
  size?: string;
  eventTracking?: EventTrackingType;
};

const ContentLink = styled(GatsbyLink)`
  text-decoration: none;
`;

const InternalLinkBlockWrapper = styled.div`
  position: relative;
  display: block;
`;

const InternalLinkLineWrapper = styled.div`
  display: inline;
`;

const InternalLinkSwitch: React.FC<Props> = ({
  to,
  linkStyle,
  children,
  fullWidth,
  margin,
  state,
  onClick,
  size,
  id,
}) => {
  switch (linkStyle) {
    case LinkStyle.PRIMARY:
      return (
        <Button
          size='lg'
          fullWidth={fullWidth}
          margin={margin}
          onClick={onClick}
          href={!to && `#${id}`}
          render={
            to &&
            (() => (
              <GatsbyLink to={id ? `${to}/#${id}` : to} state={state}>
                <CurrencyFormatter>{children}</CurrencyFormatter>
              </GatsbyLink>
            ))
          }
        >
          <CurrencyFormatter>{children}</CurrencyFormatter>
        </Button>
      );
    case LinkStyle.SECONDARY:
      return (
        <Link
          size='lg'
          margin={margin}
          isSemiBold
          onClick={onClick}
          href={!to && `#${id}`}
          render={
            to &&
            (() => (
              <GatsbyLink to={id ? `${to}/#${id}` : to} state={state}>
                <CurrencyFormatter>{children}</CurrencyFormatter>
                <Icon icon='icn_longArrowRight' />
              </GatsbyLink>
            ))
          }
        >
          <CurrencyFormatter>{children}</CurrencyFormatter>
          <Icon icon='icn_longArrowRight' />
        </Link>
      );
    case LinkStyle.BACK:
      return (
        <Link
          size='lg'
          margin={margin}
          isSemiBold
          onClick={onClick}
          render={() => (
            <GatsbyLink to={to} state={state}>
              <Icon icon='icn_longArrowLeft' />
              <CurrencyFormatter>{children}</CurrencyFormatter>
            </GatsbyLink>
          )}
        />
      );
    case LinkStyle.CONTENT:
      return (
        <ContentLink to={to} onClick={onClick}>
          {children}
        </ContentLink>
      );
    case LinkStyle.INLINE:
      return (
        <Link
          size={size || 'xl'}
          margin={margin}
          underline
          onClick={onClick}
          href={!to && `#${id}`}
          render={
            to &&
            (() => (
              <GatsbyLink to={id ? `${to}/#${id}` : to} state={state}>
                <CurrencyFormatter>{children}</CurrencyFormatter>
              </GatsbyLink>
            ))
          }
        >
          <CurrencyFormatter>{children}</CurrencyFormatter>
        </Link>
      );
    case LinkStyle.INTERNAL:
      return (
        <Link
          size='xl'
          margin={margin}
          underline
          onClick={onClick}
          href={!to && `#${id}`}
          render={
            to &&
            (() => (
              <GatsbyLink to={id ? `${to}/#${id}` : to} state={state}>
                <CurrencyFormatter>{children}</CurrencyFormatter>
              </GatsbyLink>
            ))
          }
        >
          <CurrencyFormatter>{children}</CurrencyFormatter>
        </Link>
      );
    case LinkStyle.FOOTERLINK:
      return (
        <NavigationFooterLinkWrapperAsGLink
          to={id ? `${to}/#${id}` : to}
          state={state}
          onClick={onClick}
        >
          <FooterLinkText>{children}</FooterLinkText>
          <Icon
            icon='icn_longArrowRight'
            color={colors.BlueMoon}
            size='1.6rem'
          />
        </NavigationFooterLinkWrapperAsGLink>
      );
    default:
      return (
        <Link
          onClick={onClick}
          margin={margin}
          render={() => (
            <GatsbyLink to={to} state={state}>
              <CurrencyFormatter>{children}</CurrencyFormatter>
            </GatsbyLink>
          )}
        ></Link>
      );
  }
};

const InternalLink: React.FC<Props> = ({
  to,
  linkStyle,
  children,
  fullWidth,
  margin,
  state,
  onClick,
  id,
  promo,
  inline,
  size,
  eventTracking,
}) => {
  if (inline) {
    return (
      <InternalLinkLineWrapper>
        <InternalLinkSwitch
          to={to}
          linkStyle={linkStyle}
          fullWidth={fullWidth}
          margin={margin}
          state={state}
          onClick={(e) => callbackFn(e, eventTracking, onClick)}
          size={size}
          id={id}
        >
          {children}
        </InternalLinkSwitch>
      </InternalLinkLineWrapper>
    );
  }
  if (promo) {
    return (
      <InternalLinkSwitch
        to={to}
        linkStyle={linkStyle}
        fullWidth={fullWidth}
        margin={margin}
        state={state}
        onClick={(e) => callbackFn(e, eventTracking, onClick)}
        id={id}
      >
        {children}
      </InternalLinkSwitch>
    );
  }
  return (
    <InternalLinkBlockWrapper>
      <InternalLinkSwitch
        to={to}
        linkStyle={linkStyle}
        fullWidth={fullWidth}
        margin={margin}
        state={state}
        onClick={(e) => callbackFn(e, eventTracking, onClick)}
        id={id}
      >
        {children}
      </InternalLinkSwitch>
    </InternalLinkBlockWrapper>
  );
};
export default InternalLink;

export const internalLinkQuery = graphql`
  fragment InternalLinkRichTextFragment on ContentfulInternalLink {
    contentful_id
    __typename
    id
    name
    linkText
    page {
      slug
    }
    eventTracking {
      id
      contentful_id
      category
      action
      name
      value
    }
  }
`;
