import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Paragraph as SCParagraph } from '@keytrade/components-paragraph';
import { Button } from '@keytrade/components-button';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';

import { mediaQuery } from '@/utils/helpers';
import useTranslations from '@/hooks/useTranslations';

import RenderOnClientHOC from '@/components/shared/RenderOnClientHOC';
import { COOKIE_CATEGORIES } from '@/utils/constants';
import { useCookieConsent } from '@/hooks/useCookieConsent';
import { videoPlatforms } from '@/utils/video/video.utils';
import useVideo from '@/hooks/useVideo';

export type VideoLinkType = {
  backgroundColor?: string;
  url: string;
  videoHeight?: string;
  videoWidth?: string;
  borderRadius?: boolean;
};

type Props = VideoLinkType;

type VideoProps = {
  height: string;
};

type CookieWrapperProps = {
  backgroundColor?: string;
};

const VideoContent = styled.div<VideoProps>`
  margin: ${({ height }) => (height ? '0' : '2rem 0')};
  border-radius: 1.6rem;
  overflow: hidden;
`;

const IFrameWrapper = styled.div<VideoProps>`
  position: relative;
  padding-bottom: ${({ height }) => (height ? '0' : '75%')};
  height: ${({ height }) =>
    height
      ? `300px;
  ${mediaQuery.md('height: 450px')}
  ${mediaQuery.xl(`height: ${height}`)}`
      : `0;
  `};
`;

const IframeContent = styled.iframe<VideoProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ height }) =>
    height
      ? `300px;
  ${mediaQuery.md('height: 450px')}
  ${mediaQuery.xl(`height: ${height}`)}`
      : `100%;
  `};
  width: 100%;
`;

const CookieWrapper = styled.div<CookieWrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'inherit'};
  text-align: center;
  display; flex; 
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px
  margin: 2.4rem 0 1.2rem 0;
  border: 1px solid #CEDAE1;
  border-radius: 16px;
  padding: 4.8rem;
`;

const VideoLink: React.FC<Props> = ({ backgroundColor, url, videoHeight }) => {
  if (!url) {
    return null;
  }

  const { t } = useTranslations();
  const { hasConsentFor } = useCookieConsent();
  const { videoUrl, videoPlatform } = useVideo(url);

  const showVideo =
    videoPlatform === videoPlatforms.vimeo
      ? true
      : hasConsentFor(COOKIE_CATEGORIES.ADVERTISING);

  const updateCookie = () => {
    window['OneTrust']?.ToggleInfoDisplay();
  };

  if (!showVideo) {
    const cookieWrapperOptions: CookieWrapperProps = {};

    if (backgroundColor) {
      cookieWrapperOptions.backgroundColor = backgroundColor;
    }

    return (
      <CookieWrapper {...cookieWrapperOptions}>
        <SCParagraph size='sm' margin='0 0 1.2rem 0'>
          {t('cookies_manage.text1')}
          <br />
          {t('cookies_manage.text2')}
        </SCParagraph>
        <Button size='sm' onClick={updateCookie}>
          <CurrencyFormatter>{t('cookies_manage.cta_label')}</CurrencyFormatter>
        </Button>
      </CookieWrapper>
    );
  }

  return (
    <VideoContent height={videoHeight}>
      <IFrameWrapper height={videoHeight}>
        <IframeContent
          height={videoHeight}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          src={videoUrl}
          sandbox='allow-scripts allow-same-origin'
        ></IframeContent>
      </IFrameWrapper>
    </VideoContent>
  );
};

export default RenderOnClientHOC(VideoLink);

export const videoLinkQuery = graphql`
  fragment VideoLinkRichTextFragment on ContentfulVideoLink {
    contentful_id
    __typename
    url
  }
`;
