import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';
import { config } from '@keytrade/functions';
import { Icon } from '@keytrade/components-icon';

import { PageInfoType } from '@/components/Page';
import { generateSlug } from '@/utils/SlugUtils';

const { list: colors } = config.colors;

export type Props = {
  __typename: string;
  page?: PageInfoType;
  url?: string;
  linkText?: string;
};

const sharedStyles = css`
  display: flex;
  justif-content: center;
  align-items: center;
  background-color: ${colors.BlueLight};
  padding: 16px 32px;
  color: ${colors.BlueMoon};
  font-size: 1.6rem;
  text-decoration: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  > svg {
    flex-shrink: 0;
  }
`;

const SimpleLinkWrapper = styled.a`
  ${sharedStyles}
`;

const GatsbyLinkWrapper = styled(GatsbyLink)`
  ${sharedStyles}
`;

const LinkWrapper = ({
  href,
  children,
}: PropsWithChildren<{ href: string }>) => {
  if (href.startsWith('https://')) {
    return <SimpleLinkWrapper href={href}>{children}</SimpleLinkWrapper>;
  }

  return <GatsbyLinkWrapper to={href}>{children}</GatsbyLinkWrapper>;
};

const LoginSubLink: React.FC<Props> = (props) => {
  const { url, page, linkText } = props;

  return (
    <LinkWrapper href={url ?? generateSlug(page)}>
      {linkText}
      <Icon
        icon='icn_longArrowRight'
        color={colors.GreyDark}
        margin={'0 0  0 10px'}
      />
    </LinkWrapper>
  );
};

export default LoginSubLink;
