import React, { useState, useEffect, useRef, useContext } from 'react';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import algoliasearch from 'algoliasearch/lite';
import { Logo } from '@keytrade/components-logo';
import { Icon } from '@keytrade/components-icon';
import { config } from '@keytrade/functions';
import { IconButton } from '@keytrade/components-button';
import { VisuallyHidden } from '@keytrade/components-visuallyhidden';
import { LoaderSpinner } from '@keytrade/components-loader';
import { LocalizedLink as HomepageLink } from '@plugins/keytrade-localization/LocalizedLink';
import { useLocalization } from '@plugins/keytrade-localization/useLocalization';

import LanguageSwitch, {
  LanguageSlug,
} from '@/components/navigation/LanguageSwitch';
import { mediaQuery } from '@/utils/helpers';
import Container from '@/components/Container';
import MobileMenu from '@/components/navigation/MobileMenu';
import Hamburger from '@/components/navigation/Hamburger';
import NavigationLink from '@/components/navigation/NavigationLink';
import NavigationCategory from '@/components/navigation/NavigationCategory';
import Link, { LinkStyle, LinkType } from '@/components/Link';
import LoginButton from '@/components/navigation/LoginButton';
import { getFontColorByBackground, isDarkTheme } from '@/utils/StyleUtils';
import { ImageType } from '@/components/Image';
import LoginPanel from '@/components/navigation/LoginPanel';
import useIsTablet from '@/hooks/useIsTablet';
import SearchResults from '@/components/navigation/SearchResults';
import { IsSearchingContext } from '@/context/IsSearchingContext';
import { Path } from '@/components/Page';
import { generateSlug } from '@/utils/SlugUtils';
import { addSearchParameter } from '@/utils/URLUtils';
import useTranslations from '@/hooks/useTranslations';
import { usePageCustomizationContext } from '@/hooks/usePageCustomizationContext';

import { MAIN_NAVIGATION_ID } from '../shared/htmlIds.contants';
import WebMarketTicker from './WebMarketTicker';

const { list: colors } = config.colors;

type activeProp = {
  active?: boolean;
  scrollPosition?: number;
};

type themeProp = activeProp & {
  dark: boolean;
};

const NavigationSpacer = styled.div``;

const HeaderWrapper = styled.div<activeProp>`
  position: fixed;
  display: block;
  top: 0;
  margin-top: 0;
  padding-left: 0;
  z-index: 200;
  width: 100%;
  left: 0;
  pointer-events: none;

  ${({ scrollPosition }) => `
    ${mediaQuery.lg(`
      pointer-events: all;
      transition: all 0.2s ease;
      transform:translateY(${scrollPosition}px);
    `)}
  `}
`;

const UtilityNavigation = styled.div<themeProp>`
  display: none;

  ${mediaQuery.lg(`
    display: block;
    position:relative;
    z-index: 200;
    display: block;
    height: 40px;
    width: 100%;
  `)};

  ${({ dark }) =>
    dark
      ? `
    ${mediaQuery.lg(`
    background-color: ${colors.BlueDark};
    `)}
    `
      : `
    ${mediaQuery.lg(`
    background-color: ${colors.BlueMoon};
    `)};
    `}
`;

type navBarProps = {
  active: boolean;
  dark: boolean;
  isSearching: boolean;
  isMenuOpen;
  isTablet: boolean;
};
const NavBar = styled.div<navBarProps>`
  position: relative;
  z-index: 10;
  transition: all 0.5s ease;

  ${({ active, isMenuOpen }) =>
    active
      ? `
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        ${
          !isMenuOpen &&
          'box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08), 0px 12px 20px 0px rgba(0, 0, 0, 0.14);'
        }
      `
      : `
        ${mediaQuery.lg(`
          position: relative;
          height: 74px;
          background-color: transparent;
    `)};
  `}
  ${({ active, dark, isSearching }) => {
    if (!isSearching && dark && active) {
      return `
      background-color: ${colors.BlueDark};`;
    } else if (!isSearching && !dark && active) {
      return `
      background-color: ${colors.White};`;
    } else {
      return 'background-color: transparent;';
    }
  }}
`;

const ContainerInnerWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const VerticalSeparator = styled.hr`
  align-self: center;
  height: 2.4rem;
  width: 1px;
  background-color: #253844; // Not in library
  margin: 0 1.6rem 0 3.2rem;
  border: none;
`;

const LinkWrapper = styled.div`
  ${mediaQuery.lg(`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    position: relative;
  `)}
`;

const LogoWrapper = styled.div<activeProp>`
  display: ${({ active }) => (active ? 'none' : 'block')};
  width: 143px;
  height: 34px;
  pointer-events: all;
  ${mediaQuery.lg(`
    margin-right: 34px;
`)}
`;

const CategoryContainer = styled.ul`
  ${mediaQuery.lg(`
    display: flex;
    align-items: center;
    padding: 0;
`)}
`;

const SearchIconContainer = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: block;
`;

const SearchContainer = styled.div`
  margin-right: 32px;
`;

const PersonalNavigation = styled.div`
  display: flex;
  align-items: center;
`;

const NavigationWrapper = styled.div`
  display: none;

  ${mediaQuery.lg(`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `)}
`;

const NavBarWrapper = styled.div<activeProp>`
  display: flex;
  align-items: center;
  justify-content: ${({ active }) => (active ? 'flex-end' : 'space-between')};
  height: 7.2rem;
`;

const CategoryElement = styled.li`
  ${mediaQuery.lg(`
    position: relative;
    display: block;
    list-style-type: none;
  `)}
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const LoginButtonWrapper = styled.div`
  position: relative;
`;
const LoginCaret = styled.div`
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  bottom: -19px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
  left: calc(50% - 10px);
  z-index: 1;
  filter: drop-shadow(0px -1px 1.5px rgb(0 0 0 / 8%));
`;

const Overlay = styled.div`
  display: none;
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  background-color: ${colors.Black};
  opacity: 0.4;

  ${mediaQuery.lg(`
    display: block;
  `)}
`;

const SearchForm = styled.form`
  display: none;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 1.7rem 0;
  background-color: ${colors.White};
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.08),
    0px 16px 24px 0px rgba(0, 0, 0, 0.16);

  ${mediaQuery.lg(`
    display: block;
  `)}
`;

const SearchWrapper = styled.div`
  position: relative;

  > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    pointer-events: none;
  }

  > button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
`;

const SearchBox = styled.input`
  padding: 1rem 4rem;
  width: 100%;
  font-size: 1.6rem;
  border: none;
  border-bottom: 1px solid transparent;
  font-family: inherit;

  &:focus {
    outline: none;
  }
`;

type SearchResultsContainerProps = {
  hasResults: boolean;
  scrollPosition: number;
};

const SearchResultsContainer = styled.div<SearchResultsContainerProps>`
  padding-right: 1.6rem;
  padding-left: 1.6rem;

  ${mediaQuery.lg(`
    display:block;
    max-width: 776px;
    width: 100%;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    margin-left: -388px;
    position:absolute;
    left: 50%;
    background-color: ${colors.White};
    border-radius: 0 0 16px 16px;

    ${mediaQuery.lg(`
      padding: 0;
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.08),
      0px 16px 24px 0px rgba(0, 0, 0, 0.16);
    `)}
  `)}

  ${({ hasResults, scrollPosition }) =>
    hasResults
      ? `display:block;
      ${mediaQuery.lg(`
      transform:translateY(${scrollPosition}px);
    `)}
    `
      : `display:none;
      ${mediaQuery.lg(`
      display:block;
      transform:translateY(-200%);
      `)}
      `}
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.2rem 3.6rem 7.2rem 0;

  ${mediaQuery.lg(`
    padding:0;
    min-height: 30.6rem;
  `)}
`;

export type Props = NavigationData & LoginData;

export type subCategories = {
  title: string;
  richText: any;
  image: ImageType;
  link: LinkType;
};

export type LoginData = {
  clientNumberLabel: string;
  rememberMeLabel: string;
  forgotLogin: LinkType;
  loginButtonCopy: string;
  loginText?: string;
  softkeyRecovery: LinkType;
  hardkeyActivation: LinkType;
  firstTime: LinkType;
};

export type NavigationCategories = {
  category: string;
  titlePrefix?: string;
  title: LinkType;
  subCategories: Array<subCategories>;
  footerLinks: Array<LinkType>;
};

export type NavigationData = {
  blog: LinkType;
  support: LinkType;
  contact: LinkType;
  openAccountButton: LinkType;
  navigationCategories: Array<NavigationCategories>;
  languageSlugs: Array<LanguageSlug>;
  pageColor: string;
  loginButton: string;
  locale: string;
  path: Path;
  location: URL;
};

const Navigation: React.FC<Props> = ({
  blog,
  support,
  contact,
  openAccountButton,
  navigationCategories,
  languageSlugs,
  pageColor,
  loginButton,
  clientNumberLabel,
  rememberMeLabel,
  forgotLogin,
  loginButtonCopy,
  softkeyRecovery,
  hardkeyActivation,
  firstTime,
  locale,
  location,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDesktopLoginModalOpen, setDesktopLoginModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isSearching, setIsSearching } = useContext(IsSearchingContext);
  const [searchValue, setSearchValue] = useState('');
  const [hits, setHits] = useState([]);
  const fontColor = getFontColorByBackground(pageColor);
  const [openCategoryIndex, setOpenCategoryIndex] = useState<number>();
  const navigationRef = useRef<any>();
  const loginButtonRef = useRef<any>();
  const [refs, setRefs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchId, setSearchId] = useState(-1);
  const { navigate } = useLocalization();
  const { t } = useTranslations('search');
  const isTablet = useIsTablet();

  const { pageCustomization } = usePageCustomizationContext();
  const { hideNavigation } = pageCustomization;

  const addToRefs = (el) => {
    if (el && !refs.includes(el)) {
      refs.push(el);
      setRefs(refs);
    }
  };

  const handleScroll = () => {
    const position = -window.scrollY;
    setScrollPosition(position < -40 ? -40 : position);
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    setDesktopLoginModalOpen(false);
  };

  const openSearch = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setIsSearching(true);
  };

  const closeSearch = () => {
    setSearchValue('');
    setIsSearching(false);
  };

  const openLoginModal = () => {
    setDesktopLoginModalOpen(!isDesktopLoginModalOpen);
  };

  const closeLoginModal = () => {
    setDesktopLoginModalOpen(false);
  };

  const params = new URLSearchParams(location.search);
  const loginParam = params.get('login');

  useEffect(() => {
    if (loginParam === '1') {
      openLoginModal();
    }
  }, [loginParam]);

  const handleClose = () => {
    setOpenCategoryIndex(null);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    if (isSearching && isTablet) {
      setMenuOpen(true);
    }

    const handleCloseSearch = (e) => {
      if (e.key === 'Escape') {
        closeSearch();
      }
    };

    if (isSearching) {
      window.addEventListener('keydown', handleCloseSearch, true);
    }

    return window.removeEventListener('keydown', handleCloseSearch);
  }, [isSearching]);

  const dark = isDarkTheme(pageColor);

  const LoginInfo = {
    clientNumberLabel: clientNumberLabel,
    rememberMeLabel: rememberMeLabel,
    forgotLogin: forgotLogin,
    loginButtonCopy: loginButtonCopy,
    loginButtonRef: loginButtonRef,
    loginText: loginButton,
    softkeyRecovery: softkeyRecovery,
    firstTime: firstTime,
    hardkeyActivation: hardkeyActivation,
  };

  const client = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  );

  const index = client.initIndex(`${process.env.GATSBY_ENVIRONMENT}-${locale}`);

  const doSearch = (value: string): void => {
    setSearchValue(value);

    if (value.length > 2) {
      if (searchId !== -1) window.clearTimeout(searchId);
      setLoading(true);
      setSearchId(
        window.setTimeout(() => {
          index.search(value).then(({ hits }) => {
            setHits(hits.slice(0, 5));
            setLoading(false);
          });
        }, 100),
      );
    } else {
      setHits([]);
    }
  };

  return (
    <>
      {isSearching && (
        <Overlay
          onClick={() => {
            setIsSearching(false);
            toggleMenu();
          }}
        />
      )}
      <ThemeProvider theme={{ dark: isDarkTheme(pageColor) }}>
        <HeaderWrapper scrollPosition={scrollPosition}>
          <UtilityNavigation dark={dark} scrollPosition={scrollPosition}>
            <Container>
              <ContainerInnerWrapper>
                <WebMarketTicker />
                <VerticalSeparator />
                <LinkWrapper>
                  <NavigationLink {...blog} />
                  <NavigationLink {...support} />
                  <NavigationLink {...contact} />
                  <LanguageSwitch
                    languageSlugs={languageSlugs}
                    isScrolled={scrollPosition < 0}
                    isTablet={isTablet}
                  />
                </LinkWrapper>
              </ContainerInnerWrapper>
            </Container>
          </UtilityNavigation>
          <NavBar
            dark={dark}
            active={scrollPosition < 0 || isSearching}
            isSearching={isSearching}
            isTablet={isTablet}
            isMenuOpen={isMenuOpen}
          >
            <Container>
              <NavBarWrapper active={isMenuOpen}>
                <LogoWrapper active={isMenuOpen}>
                  <HomepageLink to='/'>
                    <Logo dark={dark} />
                  </HomepageLink>
                </LogoWrapper>
                {!hideNavigation && (
                  <NavigationWrapper
                    ref={navigationRef}
                    id={MAIN_NAVIGATION_ID}
                  >
                    <CategoryContainer>
                      {navigationCategories &&
                        navigationCategories.map((navCategory, i) => (
                          <CategoryElement ref={addToRefs} key={i}>
                            <NavigationCategory
                              category={navCategory}
                              isOpen={openCategoryIndex === i}
                              handleClick={(e) => {
                                e.preventDefault();
                                setOpenCategoryIndex(
                                  i === openCategoryIndex ? null : i,
                                );
                              }}
                              scrolled={scrollPosition < 0}
                              handleClose={handleClose}
                              navigationRef={navigationRef}
                              refs={refs}
                              dark={dark}
                            />
                          </CategoryElement>
                        ))}
                    </CategoryContainer>
                    <PersonalNavigation>
                      <SearchContainer>
                        <SearchIconContainer
                          onClick={openSearch}
                          aria-pressed={isSearching}
                        >
                          <Icon
                            icon='icn_search'
                            color={fontColor}
                            size='3.2rem'
                          />
                          <VisuallyHidden>{t('open')}</VisuallyHidden>
                        </SearchIconContainer>
                      </SearchContainer>

                      <ButtonWrapper ref={loginButtonRef}>
                        <Link
                          {...openAccountButton}
                          linkStyle={LinkStyle.WHITE}
                        />
                        <LoginButtonWrapper>
                          <LoginButton
                            dark={dark}
                            openLogin={openLoginModal}
                            buttonText={loginButton}
                          />
                          {isDesktopLoginModalOpen && <LoginCaret />}
                        </LoginButtonWrapper>
                        {isDesktopLoginModalOpen && (
                          <LoginPanel
                            loginData={LoginInfo}
                            loginButtonRef={loginButtonRef}
                            desktop={true}
                            isOpen={isDesktopLoginModalOpen}
                            closeModal={closeLoginModal}
                          />
                        )}
                      </ButtonWrapper>
                    </PersonalNavigation>
                  </NavigationWrapper>
                )}
                <Hamburger
                  onClick={() => {
                    if (isSearching) closeSearch();
                    else toggleMenu();
                  }}
                  active={isMenuOpen}
                />
              </NavBarWrapper>
            </Container>
            {isSearching && (
              <SearchForm
                onSubmit={(e) => {
                  e.preventDefault();
                  closeSearch();
                  navigate(
                    addSearchParameter(generateSlug(support.page), searchValue),
                    locale,
                  );
                }}
                aria-expanded={isSearching}
                aria-modal={isSearching}
              >
                <Container narrow>
                  <SearchWrapper>
                    <Icon
                      icon='icn_search'
                      size='2.8rem'
                      color={colors.GreyBlue}
                    />
                    <SearchBox
                      autoFocus
                      placeholder={t('placeholder')}
                      onChange={(e) => doSearch(e.target.value)}
                      value={searchValue}
                    />
                    <IconButton
                      icon={<Icon icon='icn_crossLight' />}
                      kind='tertiaryNoBg'
                      noSubmit
                      onClick={closeSearch}
                      accessibilityText={t('close')}
                    />
                  </SearchWrapper>
                </Container>
              </SearchForm>
            )}
          </NavBar>

          {((isTablet && isMenuOpen) || !isTablet) && isSearching && (
            <SearchResultsContainer
              scrollPosition={scrollPosition}
              hasResults={searchValue && searchValue.length > 2}
            >
              {!isLoading && (
                <SearchResults
                  hits={hits}
                  searchValue={searchValue}
                  support={support}
                  isTablet={isTablet}
                  closeSearch={() => {
                    setIsSearching(false);
                    toggleMenu();
                  }}
                />
              )}
              {isLoading && (
                <Loader>
                  <LoaderSpinner />
                </Loader>
              )}
            </SearchResultsContainer>
          )}
        </HeaderWrapper>
        {isTablet && isMenuOpen && (
          <MobileMenu
            languageSlugs={languageSlugs}
            blog={blog}
            support={support}
            contact={contact}
            navigationCategories={navigationCategories}
            openAccountButton={openAccountButton}
            loginButton={loginButton}
            loginData={LoginInfo}
            doSearch={doSearch}
            isSearching={isSearching}
            setIsSearching={setIsSearching}
            searchValue={searchValue}
          />
        )}

        <NavigationSpacer />
      </ThemeProvider>
    </>
  );
};
export default Navigation;

export const navigationFragmentQuery = graphql`
  fragment NavigationFragment on ContentfulNavigation {
    blog {
      ...LinkFragment
    }
    support {
      ...LinkFragment
    }
    contact {
      ...LinkFragment
    }
    openAccountButton {
      ...LinkFragment
    }
    loginButton
    navigationCategories: categories {
      category
      titlePrefix
      title {
        ...LinkFragment
      }
      footerLinks {
        ...LinkFragment
      }
      subCategories: subcategories {
        title
        richText {
          raw
        }
        link {
          ...LinkFragment
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
          ...ImageFragment
        }
      }
    }
  }
`;

export const loginFragmentQuery = graphql`
  fragment LoginFragment on ContentfulLoginDialog {
    rememberMeLabel
    clientNumberLabel
    loginButtonCopy
    forgotLogin {
      ...LinkFragment
    }
    softkeyRecovery {
      ...LinkFragment
    }
    hardkeyActivation {
      ...LinkFragment
    }
    firstTime {
      ...LinkFragment
    }
  }
`;
