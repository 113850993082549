import {
  getVideoPlatform,
  getVideoPlayerUrl,
  getVideoThumbnailUrl,
} from '@/utils/video/video.utils';

import { useSiteMetadata } from './useSiteMetadata';

type Video = {
  videoUrl: string;
  videoThumbnail: string;
  videoPlatform: string;
};

const defaultValues = {
  videoUrl: '',
  videoThumbnail: '',
  videoPlatform: '',
};

const useVideo = (url: string): Video => {
  if (!url) {
    return defaultValues;
  }

  const { siteUrl: origin } = useSiteMetadata();

  const videoUrl = getVideoPlayerUrl(url, origin);
  const videoThumbnail = getVideoThumbnailUrl(url);
  const videoPlatform = getVideoPlatform(url);

  return {
    videoUrl,
    videoThumbnail,
    videoPlatform,
  };
};

export default useVideo;
