import { Icon } from '@keytrade/components-icon';
import { Link } from '@keytrade/components-link';
import { Logo } from '@keytrade/components-logo';
import { Title } from '@keytrade/components-title';
import { config } from '@keytrade/functions';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';
import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Container from '@/components/Container';
import Image from '@/components/Image';
import InternalLink from '@/components/InternalLink';
import { LinkStyle, LinkType } from '@/components/Link';
import { PageInfoType } from '@/components/Page';
import SelligentWatcher from '@/components/seo/SelligentWatcher';
import { generateSlug } from '@/utils/SlugUtils';
import { handleClick } from '@/utils/OnetrustHelpers';
import {
  EMAIL_ADDRESS,
  INSTAGRAM,
  LINKEDIN,
  PHONE_NUMBER,
  YOUTUBE,
} from '@/utils/constants';
import { mediaQuery } from '@/utils/helpers';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

const MainFooter = styled.div`
  padding: 4.8rem 0;
  background-color: ${colors.BlueMoon};

  ${mediaQuery.md('padding:7.2rem 0')};
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4.8rem;
  margin-bottom: 5rem;

  ${mediaQuery.lg(`
    flex-wrap: nowrap;
  `)};

  > * {
    ${mediaQuery.lg(`
      width: 50%;
    `)};
  }
`;

const PrimaryLinks = styled.div`
  display: none;

  ${mediaQuery.md(`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;
  `)};
`;

const LinkTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  font-size: 2.4rem;
  line-height: 1.5;
  font-family: ${({ theme }) => getFont(theme, 'bold')};
  color: ${colors.White};
`;

const LinkSubtitle = styled.div`
  font-size: 1.8rem;
  line-height: 1.5;
  font-family: ${({ theme }) => getFont(theme, 'semiBold')};
  color: ${colors.BlueLight};
`;

const Doormat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  ${mediaQuery.md(`
    flex-direction: row;
  `)};
`;

const DoormatItem = styled.div`
  min-width: 150px;
`;

const DoormatLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    color: ${colors.BlueLight};
    text-decoration: none;
    font-size: 1.6rem;
    line-height: 1.5;
    font-family: ${({ theme }) => getFont(theme, 'semiBold')};

    &:hover,
    &:focus {
      color: ${colors.White};
    }
  }
`;

const ContactFrame = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2.4rem;
  padding: 2.4rem 3.2rem;
  background-color: ${colors.White};
  border-radius: 12px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08),
    0px 8px 16px 0px rgba(0, 0, 0, 0.14);

  ${mediaQuery.md(`
    flex-direction: row;
    gap: 3.2rem;
  `)};
`;

const ContactBlock = styled.div`
  &:not(:last-child) {
    padding-bottom: 2.4rem;
    border-bottom: 1px solid ${colors.BlueLight};

    ${mediaQuery.md(`
      width: calc(100% / 3);
      padding-bottom: 0;
      border-bottom: none;
      border-right: 1px solid ${colors.BlueLight};
    `)};
  }
`;

const FooterLegalSocial = styled.div`
  padding: 4.8rem 0;
  background-color: ${colors.BlueDark};
`;

const LegalSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4rem;

  ${mediaQuery.lg(`
    flex-direction: row;
  `)};
`;

const LegalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  ${mediaQuery.md(`
    order: 2;
    justify-content: space-between;
  `)};
  ${mediaQuery.lg(`
    order: 1;
  `)};
`;

const HomePageLink = styled(GatsbyLink)`
  display: none;
  height: 3.4rem;

  ${mediaQuery.md(`
    display: block;
  `)};
`;

const CopyrightNotice = styled.p`
  font-size: 1.2rem;
  color: ${colors.BlueCloudy};
  ${mediaQuery.lg(`
    max-width: 340px;
  `)};
`;

const SecondaryLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2.6rem;

  ${mediaQuery.md(`
    order: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  `)};
  ${mediaQuery.lg(`
    order: 2;
    justify-content: center;
  `)};
`;

const SecondaryLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.6rem;

  ${mediaQuery.md(`
    flex-direction: row;
    align-items: center;
  `)};

  a {
    color: ${colors.BlueCloudy};
    font-size: 1.6rem;
    text-decoration: none;
    font-family: ${({ theme }) => getFont(theme, 'semiBold')};

    &:focus,
    &:hover {
      color: ${colors.BlueLight};
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
`;

type DoormatItem = {
  title: string;
  links: Array<LinkType>;
};

export type FooterData = {
  doormatItems: Array<DoormatItem>;
  firstLinkTarget: PageInfoType;
  firstLinkTitle: string;
  firstLinkSubtitle: string;
  secondLinkTarget: PageInfoType;
  secondLinkTitle: string;
  secondLinkSubtitle: string;
  emailLabel: string;
  phoneNumberLabel: string;
  supportLabel: string;
  supportLink: LinkType;
  copyrightNotice: {
    copyrightNotice: string;
  };
  secondaryLinks: Array<LinkType>;
};

const Footer: React.FC<FooterData> = ({
  doormatItems,
  firstLinkTarget,
  firstLinkTitle,
  firstLinkSubtitle,
  secondLinkTarget,
  secondLinkTitle,
  secondLinkSubtitle,
  emailLabel,
  phoneNumberLabel,
  supportLabel,
  supportLink,
  copyrightNotice,
  secondaryLinks,
}) => (
  <footer>
    <MainFooter>
      <Container>
        <LinksWrapper>
          <PrimaryLinks>
            <InternalLink
              linkStyle={LinkStyle.CONTENT}
              to={generateSlug(firstLinkTarget)}
            >
              <LinkTitle>
                {firstLinkTitle}
                <Icon
                  icon='icn_longArrowRight'
                  size='3.2rem'
                  margin='0 0 0 .4rem'
                />
              </LinkTitle>
              <LinkSubtitle>{firstLinkSubtitle}</LinkSubtitle>
            </InternalLink>
            <InternalLink
              linkStyle={LinkStyle.CONTENT}
              to={generateSlug(secondLinkTarget)}
            >
              <LinkTitle>
                {secondLinkTitle}
                <Icon
                  icon='icn_longArrowRight'
                  size='3.2rem'
                  margin='0 0 0 .4rem'
                />
              </LinkTitle>
              <LinkSubtitle>{secondLinkSubtitle}</LinkSubtitle>
            </InternalLink>
          </PrimaryLinks>
          <Doormat>
            {doormatItems &&
              React.Children.toArray(
                doormatItems.map((item) => (
                  // eslint-disable-next-line react/jsx-key
                  <DoormatItem>
                    <Title level='h3' size='xs' lightColor margin='0 0 1rem'>
                      {item.title}
                    </Title>
                    <DoormatLinks>
                      {React.Children.toArray(
                        item.links.map((link) => {
                          return link.__typename ===
                            'ContentfulInternalLink' ? (
                            <GatsbyLink to={generateSlug(link.page)}>
                              {link.linkText}
                            </GatsbyLink>
                          ) : (
                            <a
                              href={link.url}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {link.linkText}
                            </a>
                          );
                        }),
                      )}
                    </DoormatLinks>
                  </DoormatItem>
                )),
              )}
          </Doormat>
        </LinksWrapper>

        <ContactFrame>
          <ContactBlock>
            <Title size='xs' level='h3' margin='0 0 .7rem'>
              {emailLabel}
            </Title>
            <Link
              size='lg'
              isSemiBold
              href={`mailto:${EMAIL_ADDRESS}`}
              iconLeft={<Icon icon='icn_email' />}
            >
              {EMAIL_ADDRESS}
            </Link>
          </ContactBlock>

          <ContactBlock>
            <Title size='xs' level='h3' margin='0 0 .7rem'>
              {phoneNumberLabel}
            </Title>
            <Link
              size='lg'
              isSemiBold
              href={`tel:${PHONE_NUMBER}`}
              iconLeft={<Icon icon='icn_phone' />}
            >
              {PHONE_NUMBER}
            </Link>
          </ContactBlock>

          {supportLink && (
            <ContactBlock>
              <Title size='xs' level='h3' margin='0 0 .7rem'>
                {supportLabel}
              </Title>
              <Link
                size='lg'
                isSemiBold
                render={() => (
                  <GatsbyLink to={generateSlug(supportLink.page)}>
                    <Icon icon='icn_questionCircleLight' />
                    {supportLink.linkText}
                  </GatsbyLink>
                )}
              />
            </ContactBlock>
          )}
        </ContactFrame>
      </Container>
    </MainFooter>
    <FooterLegalSocial>
      <Container>
        <LegalSocialWrapper>
          <SecondaryLinksWrapper>
            <SecondaryLinks>
              {secondaryLinks &&
                React.Children.toArray(
                  secondaryLinks.map((link) => {
                    return !link?.linkText.includes('Cookies') &&
                      link.__typename === 'ContentfulInternalLink' ? (
                      <GatsbyLink to={generateSlug(link.page)}>
                        {link.linkText}
                      </GatsbyLink>
                    ) : (
                      <a
                        {...(!link?.linkText.includes('Cookies')
                          ? {
                              href: link.url,
                              target: '_blank',
                              rel: 'noopener noreferrer',
                            }
                          : { onClick: () => handleClick() })}
                      >
                        {link.linkText || <Image {...link.image} />}
                      </a>
                    );
                  }),
                )}
            </SecondaryLinks>
            <SocialLinks>
              <a href={INSTAGRAM} target='_blank' rel='noopener noreferrer'>
                <Icon
                  icon='icn_instagramCircle'
                  color={colors.BlueCloudy}
                  size='4.05rem'
                />
              </a>
              <a href={LINKEDIN} target='_blank' rel='noopener noreferrer'>
                <Icon
                  icon='icn_linkedinCircle'
                  color={colors.BlueCloudy}
                  size='3.6rem'
                />
              </a>
              <a href={YOUTUBE} target='_blank' rel='noopener noreferrer'>
                <Icon
                  icon='icn_youtubeCircle'
                  color={colors.BlueCloudy}
                  size='3.6rem'
                />
              </a>
            </SocialLinks>
          </SecondaryLinksWrapper>
          <LegalWrapper>
            <HomePageLink to='/'>
              <Logo dark />
            </HomePageLink>
            <CopyrightNotice>
              {copyrightNotice?.copyrightNotice.replace(
                '{year}',
                new Date().getFullYear().toString(),
              )}
            </CopyrightNotice>
          </LegalWrapper>
        </LegalSocialWrapper>
      </Container>
      <SelligentWatcher />
    </FooterLegalSocial>
  </footer>
);

export default Footer;

export const footerFragmentQuery = graphql`
  fragment FooterFragment on ContentfulFooter {
    firstLinkTitle
    firstLinkSubtitle
    firstLinkTarget {
      ...PagePathFragment
    }
    secondLinkTitle
    secondLinkSubtitle
    secondLinkTarget {
      ...PagePathFragment
    }
    doormatItems {
      title
      links {
        ...LinkFragment
      }
    }
    emailLabel
    phoneNumberLabel
    supportLabel
    supportLink {
      ...LinkFragment
    }
    secondaryLinks {
      ...LinkFragment
    }
    copyrightNotice {
      copyrightNotice
    }
  }
`;
