import React from 'react';

import { Title as SCTitle } from '@keytrade/components-title';

import CurrencyFormatter from '@/formatters/CurrencyFormatter';
import styled from 'styled-components';
export type TitleType = {
  size?: string;
  forceMobileSize?: string;
  level?: string;
  margin?: string;
  lightColor?: boolean;
  inherit?: boolean;
  center?: boolean;
  lightWeight?: boolean;
  id?: string;
};

type Props = TitleType;

const StyledTitle = styled(SCTitle)`
  scroll-margin-top: 9rem;
`;

const Title: React.FC<Props> = (props) => {
  return (
    <StyledTitle {...props}>
      <CurrencyFormatter>{props.children}</CurrencyFormatter>
    </StyledTitle>
  );
};

export default Title;
