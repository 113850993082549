import React from 'react';
import { ThemeProvider } from 'styled-components';
import { graphql } from 'gatsby';
import { generateSlug } from '@/utils/SlugUtils';

import styled from 'styled-components';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { List } from '@keytrade/components-list';
import { Span } from '@keytrade/components-span';
import { config } from '@keytrade/functions';
import Image from '@/components/Image';
import Title from '@/components/Title';
import Paragraph from '@/components/Paragraph';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';
import Badge from '@/components/Badge';
import AssetLink from '@/components/AssetLink';
import Footnote from '@/components/Footnote';
import VideoLink from '@/components/VideoLink';
import Quote from '@/components/Quote';
import Tip from '@/components/Tip';
import Table from '@/components/Table';
import { LinkStyle } from '@/components/Link';
import ExternalLink from '@/components/ExternalLink';
import ColoredText from '@/components/ColoredText';
import CostTable from '@/components/CostTable';
import { generatePageTypeSlug } from '@/utils/SlugUtils';
import InternalLink from '@/components/InternalLink';
import { DefaultPaths } from '@/components/Page';
import { mediaQuery } from '@/utils/helpers';
import SavingRates from '@/components/SavingRates';
import TableOfContent from '@/components/TableOfContent';
import ExternalContent from '@/components/ExternalContent';
import slugify from 'slugify';

import {
  getCellContentValue,
  renderInlineAssetLink,
  renderInlineInternalLink,
} from '@/utils/Richtext.utils';

type WrapperProps = {
  marginBottom: string;
};

const { list: colors } = config.colors;
const { getFont } = config.fonts;

const RichTextWrapper = styled.div<WrapperProps>`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}` : '0'};

  hr {
    border: 0;
    border-top: 1px solid ${colors.BlueFog};
    margin: 3.2rem 0;
    ${mediaQuery.md('margin: 4.8rem 0;')};
  }
  > *:last-child {
    margin-bottom: 0;
  }
  > *:first-child {
    margin-top: 0;
  }

  i {
    font-family: ${({ theme }) => getFont(theme, 'italic')};
  }

  p,
  span,
  li,
  i,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  em,
  blockquote {
    white-space: pre-line;
  }
`;

const RichTextImage = styled(Image)`
  margin: 2rem 0;
  border-radius: 1.6rem;
`;

export const handleTableData = (content) => {
  let hideHeader = false;
  let tableCols = [];
  const tableData = [];
  const columnConfig = {
    label: 'empty-header',
    textAlign: 'left',
  };

  content?.forEach((row) => {
    const rowData = {};

    row.content.forEach((cell, cellIndex) => {
      const { content, nodeType } = cell;
      const renderedValue = [];

      content[0].content.forEach((item) => {
        const value = getCellContentValue(item);
        renderedValue.push(value);
      });

      if (nodeType === 'table-header-cell') {
        tableCols.push({
          ...columnConfig,
          label: renderedValue,
          key: `data-${cellIndex}`,
        });
      } else {
        rowData[`data-${cellIndex}`] = (
          <CurrencyFormatter>
            {React.Children.toArray(renderedValue.map((item) => item))}
          </CurrencyFormatter>
        );
      }
    });

    if (rowData && Object.keys(rowData).length > 0) {
      tableData.push(rowData);
    }
  });

  if (tableData.length > 0 && tableCols.length === 0) {
    hideHeader = true;
    const columnKeys = Object.keys(tableData[0]);

    tableCols = columnKeys.map((columnKey: string) => {
      return {
        ...columnConfig,
        key: columnKey,
      };
    });
  }

  return {
    hideHeader,
    tableCols,
    tableData,
  };
};

export type RichTextType = {
  richText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  defaultPaths?: DefaultPaths;
};

type RichTextOptions = {
  theme?: string;
  marginBottom?: string;
  textSize?: string;
  parentContentType?: string;
  noCheckMarkListIcons?: boolean;
};

type Props = {
  data: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  customComponents?: any;
  options?: RichTextOptions;
  defaultPaths?: DefaultPaths;
  locale?: string;
  isInternalLinkButton?: boolean;
};

const Richtext: React.FC<Props> = ({
  data,
  customComponents = {},
  options = {},
  defaultPaths = {},
  locale,
  isInternalLinkButton,
}) => {
  if (!data?.raw) {
    return null;
  }

  const isDarkTheme = options.theme === 'dark';
  const isEmbeddedInSCB = options.parentContentType === 'SingleCenteredBlock';

  const renderHeading1 = (node, children) => {
    return (
      <Title
        className='heading-1 heading'
        size='xxl'
        level='h1'
        margin='5.6rem 0 2rem'
        lightColor={isDarkTheme}
        id={slugify(documentToPlainTextString(node))}
      >
        {children}
      </Title>
    );
  };
  const renderHeading2 = (node, children) => {
    return (
      <Title
        className='heading-2 heading'
        size='xl'
        level='h2'
        margin='3.2rem 0 1.2rem'
        lightColor={isDarkTheme}
        id={slugify(documentToPlainTextString(node))}
      >
        {children}
      </Title>
    );
  };
  const renderHeading3 = (node, children) => {
    return (
      <Title
        className='heading-3 heading'
        size='lg'
        level='h3'
        margin='3.2rem 0 1.2rem'
        lightColor={isDarkTheme}
        id={slugify(documentToPlainTextString(node))}
      >
        {children}
      </Title>
    );
  };
  const renderHeading4 = (node, children) => {
    return (
      <Title
        className='heading-4 heading'
        size='md'
        level='h4'
        margin='3.2rem 0 1.2rem'
        lightColor={isDarkTheme}
        id={slugify(documentToPlainTextString(node))}
      >
        {children}
      </Title>
    );
  };
  const renderHeading5 = (node, children) => {
    return (
      <Title
        className='heading-5 heading'
        size='sm'
        level='h5'
        margin='3.2rem 0 1.2rem'
        lightColor={isDarkTheme}
        id={slugify(documentToPlainTextString(node))}
      >
        {children}
      </Title>
    );
  };
  const renderParagraph = (node, children) => {
    //remove the p tag if children are empty (<span></span>) else there is an empty p tag
    if (
      children.length === 1 &&
      children[0]?.props?.children?.props?.children === ''
    )
      return <></>;
    return (
      <Paragraph
        size={options.textSize}
        color={isDarkTheme ? 'White' : 'BlueDark'}
        margin='1.2rem 0'
      >
        {children}
      </Paragraph>
    );
  };
  const renderUnorderedList = (node) => (
    <List
      type={options.noCheckMarkListIcons ? 'unordered' : 'iconList'}
      iconColor={!options.noCheckMarkListIcons && 'BlueSky'}
      icon={!options.noCheckMarkListIcons && 'icn_check'}
      size='xl'
    >
      {React.Children.toArray(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        node.content.map((childNode) => renderListItem(childNode)),
      )}
    </List>
  );
  const renderOrderedList = (node) => (
    <List type='ordered' size='xl'>
      {React.Children.toArray(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        node.content.map((childNode) => renderListItem(childNode)),
      )}
    </List>
  );
  const renderLink = (node, children) => (
    <ExternalLink
      url={node.data.uri}
      linkStyle={LinkStyle.INLINE}
      locale={locale}
    >
      {children}
    </ExternalLink>
  );
  const renderEntryLink = (node, children) => {
    return (
      <InternalLink
        to={generatePageTypeSlug(node.data.target, defaultPaths)}
        linkStyle={LinkStyle.INLINE}
      >
        {children}
      </InternalLink>
    );
  };

  const renderInlineEntries = (node) => {
    if (!node.data.target) return null;
    switch (node.data.target.__typename) {
      case 'ContentfulBadge':
        return <Badge {...node.data.target} />;
      case 'ContentfulColoredText':
        return <ColoredText {...node.data.target} />;
      case 'ContentfulAssetLink':
        return renderInlineAssetLink(node);
      case 'ContentfulInternalLink':
        return renderInlineInternalLink(node);
      case 'ContentfulSavingsRate':
        return <SavingRates {...node.data.target} dark={isDarkTheme} />;
      case 'ContentfulExternalContent':
        return <ExternalContent {...node.data.target} locale={locale} />;
      case 'ContentfulExternalLink':
        const { name, linkName, image } = node.data.target;
        const linkLabel = linkName || name;

        return (
          <ExternalLink
            {...node.data.target}
            linkStyle={!!image ? LinkStyle.IMAGE : LinkStyle.INLINE}
            size='xl'
          >
            {linkLabel}
          </ExternalLink>
        );
      default:
        return null;
    }
  };

  const renderEntries = (node, parentContentType) => {
    if (!node.data.target) return null;

    switch (node.data.target.__typename) {
      case 'ContentfulAssetLink':
        return <AssetLink {...node.data.target} margin='1.2rem 0' />;
      case 'ContentfulFootnote':
        return (
          <Footnote
            {...node.data.target}
            color={
              isDarkTheme ? 'White' : isEmbeddedInSCB ? 'GreyBlue' : 'BlueDark'
            }
          />
        );
      case 'ContentfulVideoLink':
        return <VideoLink {...node.data.target} />;
      case 'ContentfulQuote':
        return <Quote {...node.data.target} dark={isDarkTheme} />;
      case 'ContentfulTip':
        return <Tip {...node.data.target} />;
      case 'ContentfulInternalLink':
        if (node.data.target.page && node.data.target.page.name) {
          return (
            <InternalLink
              to={generateSlug(node.data.target.page)}
              linkStyle={isInternalLinkButton ? 1 : 8}
              {...node.data.target}
            >
              {node.data.target.page.name}
            </InternalLink>
          );
        } else {
          return null;
        }
      case 'ContentfulExternalLink':
        const { name, linkName, image } = node.data.target;
        const linkLabel = linkName || name;

        return (
          <ExternalLink
            {...node.data.target}
            linkStyle={!!image ? LinkStyle.IMAGE : LinkStyle.INLINE}
            size='xl'
          >
            {linkLabel}
          </ExternalLink>
        );
      case 'ContentfulSavingsRate':
        return <SavingRates {...node.data.target} dark={isDarkTheme} />;
      case 'ContentfulExternalContent':
        return <ExternalContent {...node.data.target} locale={node_locale} />;
      case 'ContentfulCostTable':
        return <CostTable {...node.data.target} dark={isDarkTheme} />;
      case 'ContentfulTableOfContent':
        return <TableOfContent data={data} />;
      default:
        return null;
    }
  };

  const renderAssets = (node) => {
    if (!node.data?.target) return <></>;
    if (
      node.data.target.file?.contentType &&
      node.data.target.file?.contentType.includes('image')
    )
      return <RichTextImage {...node.data.target} />;
    // optionally we can create AssetLink but there is already an AssetLink via entries maybe it's confusing?
    //  return <AssetLink asset={node.data.target} />;
  };

  const renderListItem = (node) => {
    return (
      <Span color={isDarkTheme ? 'White' : 'BlueDark'}>
        <CurrencyFormatter>
          {documentToReactComponents(node, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => children,
              [BLOCKS.OL_LIST]: renderOrderedList,
              [BLOCKS.UL_LIST]: renderUnorderedList,
              [BLOCKS.LIST_ITEM]: (node, children) => children,
              [INLINES.EMBEDDED_ENTRY]: renderInlineEntries,
              [BLOCKS.EMBEDDED_ENTRY]: renderEntries,
              [BLOCKS.EMBEDDED_ASSET]: renderAssets,
              [INLINES.HYPERLINK]: renderLink,
              [INLINES.ENTRY_HYPERLINK]: renderEntryLink,
            },
          })}
        </CurrencyFormatter>
      </Span>
    );
  };

  const renderBold = (text) => <b>{text}</b>;
  const renderItalic = (text) => <i>{text}</i>;
  const renderUnderline = (text) => <u>{text}</u>;

  const customRendering = {
    renderMark: {
      [MARKS.BOLD]: customComponents.Bold || renderBold,
      [MARKS.ITALIC]: customComponents.Italic || renderItalic,
      [MARKS.UNDERLINE]: customComponents.Underline || renderUnderline,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: customComponents.Heading1 || renderHeading1,
      [BLOCKS.HEADING_2]: customComponents.Heading2 || renderHeading2,
      [BLOCKS.HEADING_3]: customComponents.Heading3 || renderHeading3,
      [BLOCKS.HEADING_4]: customComponents.Heading4 || renderHeading4,
      [BLOCKS.HEADING_5]: customComponents.Heading5 || renderHeading5,
      [BLOCKS.PARAGRAPH]: customComponents.Paragraph || renderParagraph,
      [BLOCKS.UL_LIST]: customComponents.List || renderUnorderedList,
      [BLOCKS.OL_LIST]: customComponents.List || renderOrderedList,
      [BLOCKS.LIST_ITEM]: customComponents.ListItem || renderListItem,
      [INLINES.HYPERLINK]: customComponents.Link || renderLink,
      [INLINES.ENTRY_HYPERLINK]: customComponents.EntryLink || renderEntryLink,
      [INLINES.EMBEDDED_ENTRY]: renderInlineEntries,
      [BLOCKS.EMBEDDED_ENTRY]: renderEntries,
      [BLOCKS.EMBEDDED_ASSET]: renderAssets,
      [BLOCKS.TABLE]: function RenderTable(node) {
        const { hideHeader, tableCols, tableData } = handleTableData(
          node.content,
        );

        return (
          <Table
            hideHeader={hideHeader}
            tableCols={tableCols}
            tableData={tableData}
            isAlternateDesign={isEmbeddedInSCB}
            dark={isDarkTheme}
          />
        );
      },
    },
    renderText: function wrapInSpan(text) {
      return (
        <span>
          <CurrencyFormatter>{text}</CurrencyFormatter>
        </span>
      );
    }, // Wrap each text node in a <span> so that we can offset the negative margin on the container
  };

  return (
    <RichTextWrapper marginBottom={options.marginBottom}>
      <ThemeProvider theme={{ dark: isDarkTheme }}>
        {renderRichText(data, customRendering)}
      </ThemeProvider>
    </RichTextWrapper>
  );
};

export default Richtext;

export const richTextQuery = graphql`
  fragment RichTextFragment on ContentfulRichText {
    raw
    references {
      ... on ContentfulBadge {
        ...BadgeRichTextFragment
      }
      ... on ContentfulExternalContent {
        contentful_id
        __typename
        name
        ExternalContent: content
      }
      ... on ContentfulAssetLink {
        ...AssetLinkRichTextFragment
      }
      ... on ContentfulVideoLink {
        ...VideoLinkRichTextFragment
      }
      ... on ContentfulFootnote {
        ...FootnoteRichTextFragment
      }
      ... on ContentfulQuote {
        ...QuoteRichTextFragment
      }
      ... on ContentfulTip {
        ...TipRichTextFragment
      }
      ... on ContentfulTable {
        ...TableRichTextFragment
      }
      ... on ContentfulColoredText {
        ...ColoredTextRichTextFragment
      }
      ... on ContentfulCostTable {
        ...CostTableRichTextFragment
      }
      ... on ContentfulTableOfContent {
        ...TableOfContentFragment
      }
      ... on ContentfulPage {
        ...PageRichTextFragment
      }
      ... on ContentfulHomepage {
        contentful_id
        node_locale
      }
      ... on ContentfulSavingsRate {
        ...SavingRatesRichTextFragment
      }
      ... on ContentfulAsset {
        contentful_id
        __typename
        gatsbyImageData(layout: CONSTRAINED)
        ...ImageFragment
      }
      ... on ContentfulInternalLink {
        ...LinkFragment
      }
      ... on ContentfulExternalLink {
        contentful_id
        __typename
        url
        name
        linkText
        image {
          description
          file {
            fileName
            url
          }
        }
      }
    }
  }
`;
