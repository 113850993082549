import React from 'react';
import styled from 'styled-components';

import { mediaQuery } from '@/utils/helpers';
import { config } from '@keytrade/functions';
const { list: colors } = config.colors;

export type Props = HamburgerData;

export type HamburgerData = {
  active: boolean;
  onClick: any;
};

const HamburgerContainer = styled.button<HamburgerData>`
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  height: 3.6rem;
  width: 3.6rem;
  pointer-events: all;
  background: transparent;
  transition: all 0.2s ease-out;
  cursor: pointer;
  background-color: ${({ active }) => (active ? colors.BlueSky : '')};
  border-radius: ${({ active }) => (active ? '50%' : '')};
  margin-right: ${({ active }) => (active ? '0.2rem' : '-0.2rem')};
  border: none;

  &:before {
    content: '';
    width: 20px;
    height: 2px;
    position: absolute;
    background-color: ${({ theme }) =>
      theme.dark ? colors.White : colors.BlueDark};
    transition: all 0.2s ease-out;
    will-change: transform;
    transform: ${({ active }) =>
      active ? 'translateY(0) rotate(45deg)' : 'translateY(-6px)'};
  }

  &:after {
    content: '';
    width: 20px;
    height: 2px;
    position: absolute;
    background-color: ${({ theme }) =>
      theme.dark ? colors.White : colors.BlueDark};
    transition: all 0.2s ease-out;
    will-change: transform;
    transform: ${({ active }) =>
      active
        ? 'translateY(0) rotate(-45deg)'
        : 'transform: translateY(0.6rem)'};
  }
  ${mediaQuery.lg(`
    display: none;
  `)}

  ${({ active }) =>
    active
      ? `
      &:before {
        background-color: ${colors.White};
      }
      &:after {
        background-color: ${colors.White};
      }
    `
      : `
        `}
`;

const Hamburger: React.FC<Props> = ({ active, onClick }) => {
  return <HamburgerContainer onClick={onClick} active={active} />;
};

export default Hamburger;
