const fixedProtocol = (url: string): string => {
  return url.indexOf('//') === 0 ? url.replace('//', 'https://') : url;
};

const addUrlParameter = (href: string, name: string, value: string): string => {
  const url = new URL(fixedProtocol(href));
  const searchParams = new URLSearchParams(url.search);
  searchParams.set(name, value);
  url.search = searchParams.toString();
  return url.toString();
};

const getKeytradeBackendURL = (): string => {
  const GATSBY_API_ORIGIN = process.env.GATSBY_API_ORIGIN as string;
  const BASE_PATH = GATSBY_API_ORIGIN.includes('localhost')
    ? ''
    : '/node/backend';
  return `${GATSBY_API_ORIGIN}${BASE_PATH}`;
};

const getKeytradePublicPHPURL = (): string => {
  const GATSBY_PUBLIC_PHP_ORIGIN = process.env
    .GATSBY_PUBLIC_PHP_ORIGIN as string;

  if (GATSBY_PUBLIC_PHP_ORIGIN) {
    return GATSBY_PUBLIC_PHP_ORIGIN;
  }

  return '';
};

const addSearchParameter = (href: string, value: string): string => {
  return href + '?search=' + encodeURIComponent(value);
};

const getCookieURL = (lang: string): string => {
  let URL = `${process.env.GATSBY_SITE_URL}/${lang}/`;

  switch (lang) {
    case 'nl':
      URL += 'gebruik-cookies';
      break;
    case 'fr':
      URL += 'usage-cookies';
      break;
    case 'en':
    default:
      URL += 'use-cookies';
      break;
  }

  return URL;
};

export {
  fixedProtocol,
  addUrlParameter,
  getKeytradeBackendURL,
  getKeytradePublicPHPURL,
  addSearchParameter,
  getCookieURL,
};
