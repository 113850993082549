import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { config } from '@keytrade/functions';

import { Icon } from '@keytrade/components-icon';
import { InfoBox } from '@keytrade/components-infobox';

import Paragraph from '@/components/Paragraph';

const { list: colors } = config.colors;

export type TipType = {
  text: { text: string };
};

type Props = TipType;

const InnerWrapper = styled.div`
  display: flex;
  svg {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

const Tip: React.FC<Props> = ({ text }) => {
  return (
    <InfoBox type='disclaimer'>
      <InnerWrapper>
        <Icon
          icon='icn_idea'
          size='24px'
          margin='0 1.2rem 0 0'
          color={colors.GreyDark}
        />
        <Paragraph color='BlueDark' size='sm'>
          {text.text}
        </Paragraph>
      </InnerWrapper>
    </InfoBox>
  );
};

export default Tip;

export const tipQuery = graphql`
  fragment TipRichTextFragment on ContentfulTip {
    contentful_id
    __typename
    text {
      text
    }
  }
`;
