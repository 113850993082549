import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Table as SCTable } from '@keytrade/components-table';
import { config } from '@keytrade/functions';
import { mediaQuery } from '@/utils/helpers';

const { list: colors } = config.colors;

export type TableType = {
  hideHeader: boolean;
  isAlternateDesign: boolean;
  dark: boolean;
  tableCols: Array<any>;
  tableData: Array<any>;
};

const Table: React.FC<TableType> = ({
  hideHeader,
  tableCols,
  tableData,
  isAlternateDesign,
  dark,
}) => {
  return (
    <TableWrapper isAlternateDesign={isAlternateDesign} dark={dark}>
      <SCTable
        columns={tableCols}
        data={tableData}
        hideHeader={hideHeader}
        isLight={!isAlternateDesign}
        styledComponentsV4
        isAlternateDesign={isAlternateDesign}
        dark={dark}
      />
    </TableWrapper>
  );
};

export default Table;

const TableWrapper = styled.div<TableType>`
  ${({ isAlternateDesign }) =>
    isAlternateDesign &&
    `
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  ${mediaQuery.md(`
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  `)}
  border-radius: 2.4rem;
  box-shadow: 0px 2px 11px 0px #0000000D, 0px 0px 23px 0px #0000000D;
  tbody tr:last-of-type td {
      border-bottom: none !important;
    }
  td, th { 
      padding: 2.1rem 3.2rem;
      text-align: center;
      &:first-of-type {
      text-align: left;
      }
    }
  `}
  ${({ isAlternateDesign }) =>
    !isAlternateDesign &&
    `
    tbody tr:last-of-type td:first-of-type {
      border-bottom-left-radius: .4rem !important;
    }
    tbody tr:last-of-type td:last-of-type {
      border-bottom-right-radius: .4rem !important;
    }
  `}
  ${({ dark }) =>
    !dark &&
    `
    tbody tr td, thead tr th {
      background-color: ${colors.White};
    }
    tbody tr:first-of-type td{
      border-top: 0.1rem solid ${dark ? colors.BlueDark : colors.GreyWhite};
    }
  `}
`;

export const tableQuery = graphql`
  fragment TableRichTextFragment on ContentfulTable {
    contentful_id
    __typename
    content {
      tableData
    }
  }
`;
