import React, { useState, useEffect } from 'react';

const RenderOnClientHOC = <T,>(WrappedComponent: React.FC<T>): React.FC<T> => {
  const Component: React.FC<T> = (props) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
      setIsClient(true);
      return () => setIsClient(false);
    }, []);

    return isClient ? <WrappedComponent {...props} /> : null;
  };

  return Component;
};

export default RenderOnClientHOC;
