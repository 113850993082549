export const youtubeRegExp =
  /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/;

export const getYoutubeThumbnailUrl = (id: string): string =>
  `https://img.youtube.com/vi/${id}/hqdefault.jpg`;

export const getYoutubeVideoPlayerUrl = (id: string, origin: string): string =>
  `https://www.youtube-nocookie.com/embed/${id}?enablejsapi=1&origin=${origin}&iv_load_policy=3&rel=0&autoplay=0&wmode=transparent`;

export const getYoutubeIdFromUrl = (url: string): string => {
  const result = url.match(youtubeRegExp);
  return result?.length ? result[3] : null;
};

export const getYoutubeThumbnailFromVideoUrl = (url: string): string => {
  return getYoutubeThumbnailUrl(getYoutubeIdFromUrl(url));
};

export const getVideoUrlForYoutube = (url: string, origin: string): string => {
  const videoId = getYoutubeIdFromUrl(url);
  return getYoutubeVideoPlayerUrl(videoId, origin);
};
