import React from 'react';
import { Paragraph as SCParagraph } from '@keytrade/components-paragraph';

import CurrencyFormatter from '@/formatters/CurrencyFormatter';

export type TitleType = {
  color?: string;
  size?: string;
  weight?: string;
  margin?: string;
  lightColor?: boolean;
};

type Props = TitleType;

const Paragraph: React.FC<Props> = ({ children, ...props }) => (
  <SCParagraph {...props}>
    <CurrencyFormatter>{children}</CurrencyFormatter>
  </SCParagraph>
);

export default Paragraph;
