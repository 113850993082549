"use strict";

exports.__esModule = true;
exports.useLocalization = void 0;
var React = _interopRequireWildcard(require("react"));
var _gatsby = require("gatsby");
var _LocaleProvider = require("./LocaleProvider");
var _helpers = require("./helpers");
var _options = require("./options");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var useLocalization = exports.useLocalization = function useLocalization() {
  var _React$useContext = React.useContext(_LocaleProvider.LocaleContext),
    locale = _React$useContext.locale,
    changeLocale = _React$useContext.changeLocale;
  var languages = (0, _options.getLanguages)();
  var localeNavigate = function localeNavigate(to, language, options) {
    if (language === void 0) {
      language = locale;
    }
    if (language !== locale) {
      changeLocale(language);
    }
    (0, _gatsby.navigate)((0, _helpers.localizedPath)(language, to), options);
  };
  return {
    locale: locale,
    changeLocale: changeLocale,
    navigate: localeNavigate,
    defaultLang: _options.defaultLang,
    allLang: languages,
    localizedPath: _helpers.localizedPath
  };
};