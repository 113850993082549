export const track = (mainTag: string, lang: string): boolean => {
  if (typeof window !== 'undefined' && typeof window['wa'] !== 'undefined') {
    const JSON_OBJECT = `{ "finishedCallback": "bt_trackingFinishedCallback", "errorCallback": "bt_errorCallback", "tags":[{"tag":"MAINTAG","value":"${mainTag}"}, {"tag":"LANGUAGE","value":"${lang}"}], "isTargeting":true}`;
    window.wa.bt_queue.push(JSON_OBJECT);

    return true;
  }

  return false;
};
