const isBrowser = typeof window !== 'undefined';
(function () {
  'use strict';

  if (!isBrowser) {
    return;
  }

  window.SaxoTCCommon = window.SaxoTCCommon || {};

  window.SaxoTCCommon.renderTable = function (data) {
    if (!(data.RenderOptions & 1)) {
      // if ShowTable=CHECKED then FALSE else TRUE
      return '';
    } else if ((data.RenderOptions & 4) === 4) {
      // Render as category view
      return window.SaxoTCCommon.CategoryView.renderView(data);
    } else {
      // Render as table
      return this.renderTableView(data);
    }
  };

  window.SaxoTCCommon.renderTableView = function (data) {
    var bodyHtml;

    if (typeof data.GroupColumn === 'undefined' || data.GroupColumn === 0) {
      if (data.Rows.length === 0) {
        return '';
      }
      bodyHtml =
        '<tbody>' + window.SaxoTCCommon.renderBody(data).join('') + '</tbody>';
    } else {
      bodyHtml = window.SaxoTCCommon.GroupView.renderBody(data).join('');
    }

    var idData =
      typeof data.WidgetId === 'undefined'
        ? ''
        : 'data-widget-id="' + data.WidgetId + '"';
    var html =
      '<table class="widget" ' +
      idData +
      ' cellspacing="0" style="width:100%"><thead><tr>';
    var j;
    for (j = 0; j < data.Columns.length; j++) {
      var className = 'column-' + data.Columns[j].Id + (j === 0 ? ' r1' : '');
      className += ' h-font';
      if (data.Columns[j].Alignment > 3)
        html +=
          '<th data-column-id="' +
          data.Columns[j].Id +
          '" data-column-type="' +
          data.Columns[j].Type +
          '" class="' +
          className +
          '" style="width:50px;word-spacing:30px">';
      else
        html +=
          '<th data-column-id="' +
          data.Columns[j].Id +
          '" data-column-type="' +
          data.Columns[j].Type +
          '" class="' +
          className +
          '">';
      html += data.Columns[j].Name + '</th>';
    }
    html += '</tr></thead>' + bodyHtml + '</table>';
    return html;
  };

  window.SaxoTCCommon.renderStyles = function (scopeId, data) {
    var result = '',
      definition = data.StyleDefinition,
      values = data.Styles,
      i,
      group,
      j,
      item,
      style,
      value,
      defItem,
      props,
      val,
      key;

    var replaceAll = function (text, target, replacement) {
      return text.split(target).join(replacement);
    };

    var getDefinitions = {};
    for (i = 0; definition.Groups && i < definition.Groups.length; i++) {
      group = definition.Groups[i];
      for (j = 0; j < group.Items.length; j++) {
        item = group.Items[j];
        getDefinitions[item.Id.toString()] = item;
      }
    }

    style = {};
    for (i = 0; i < values.length; i++) {
      value = values[i];
      defItem = getDefinitions[value[0]];

      for (j = 0; j < defItem.Selectors.length; j++) {
        props = style[defItem.Selectors[j]] || '';
        val = value[1];
        if (val !== defItem.NullValue) {
          style[defItem.Selectors[j]] =
            props +
            '\t' +
            defItem.Properties[j] +
            ':' +
            String(value[1]).replace(defItem.Suffix, '') +
            defItem.Suffix +
            ';\r\n';
        }
      }
      for (j = 0; j < defItem.CssValues.length; j++) {
        style[defItem.CssValues[j][0]] =
          '\t' + defItem.CssValues[j][1] + '\r\n';
      }
    }

    for (key in style) {
      if (style.hasOwnProperty(key)) {
        result = result + scopeId + ' ' + key + ' {\r\n' + style[key] + '}\r\n';
      }
    }

    for (i = 0; definition.Css && i < definition.Css.length; i++) {
      result =
        result +
        scopeId +
        ' ' +
        replaceAll(definition.Css[i], /;/, ';\r\n\t')
          .replace(/\{/, ' {\r\n\t')
          .replace(/\t\}/, '}\r\n');
    }

    for (i = 0; i < data.Columns.length; i++) {
      result =
        result +
        scopeId +
        ' .column-' +
        data.Columns[i].Id +
        '{ text-align: ' +
        ['auto', 'left', 'right', 'center'][
          data.Columns[i].Alignment > 3
            ? parseInt(data.Columns[i].Alignment.toString()[1])
            : data.Columns[i].Alignment
        ] +
        ' }';
    }

    result =
      result +
      scopeId +
      ' .grp-img { font-family: Arial; font-size:10px;border:1px solid;display:inline-block;height:7px;line-height:7px;width:7px;margin-right:7px; }';
    result = result + scopeId + ' .col-grp-img {padding-left:1px;width:6px; }';

    return result;
  };

  window.SaxoTCCommon.renderBody = function (data) {
    var rows = [],
      htmlRow,
      i,
      row,
      j,
      cell,
      className;
    SaxoTCCommon.dataSort(data.Rows);
    for (i = 0; i < data.Rows.length; i++) {
      row = data.Rows[i];
      htmlRow = '<tr>';

      for (j = 0; j < row.length; j++) {
        cell = row[j];
        cell = cell === null ? '' : cell;
        cell = cell === '' ? '&nbsp;' : cell;
        cell = cell
          .replace(/&band;/gi, '<hr>')
          .replace(
            /&break;/gi,
            '<hr style="border-color: transparent;">&nbsp;<br>',
          );

        className = i % 2 === 0 ? 'bgrCol' : 'bgrCol2';
        className += j === 0 ? ' r1' : '';
        className += ' col' + j;
        className += ' column-' + data.Columns[j].Id;
        htmlRow += '<td class="' + className + '">' + cell + '</td>';
      }
      htmlRow += '</tr>';
      rows.push(htmlRow);
    }

    return rows;
  };

  (window.SaxoTCCommon.renderSearchBox = function (data) {
    var renderSearch =
        (data.RenderOptions & 8 ? true : false) && !data.GroupColumn,
      widgetId = 'widget' + data.WidgetUid + data.Language,
      queryTable = window.SaxoTCCommon[widgetId] || {},
      searchfn = function () {
        SaxoTCCommon.onChangeFunction.apply(this, [data]);
      },
      wrapper = document.createElement('div'),
      searchBox = document.createElement('input'),
      placeholder;

    if (!renderSearch) {
      return;
    }

    searchBox.setAttribute('type', 'text');
    searchBox.setAttribute('maxlength', '50');

    searchBox.setAttribute('id', widgetId + 'search');

    if (searchBox.placeholder === null) {
      placeholder = document.createElement('label');
      placeholder.innerText = data.SearchLabel;
      placeholder.style.position = 'absolute';
      placeholder.style.left = '7px';
      placeholder.style.top = '3px';
      placeholder.style.width = '147px';
      placeholder.style.height = '15px';
      placeholder.style.color = 'rgb(186, 186, 186)';
      //placeholder.setAttribute('style', 'left: 7px; top: 3px; width: 147px; height: 15px; color: rgb(186, 186, 186); position: absolute;');
      placeholder.onclick = function () {
        console.log('Click!');
        searchBox.focus();
      };
      searchBox.onfocus = function () {
        placeholder.style.display = 'none';
      };
      searchBox.onblur = function () {
        if (this.value === '') {
          placeholder.style.display = 'block';
        }
      };
      wrapper.style.position = 'relative';
    } else {
      searchBox.setAttribute('placeholder', data.SearchLabel);
    }

    window.SaxoTCCommon[widgetId] = queryTable;
    queryTable[searchBox.id] = {
      columnType: -1,
      columnName: '*',
      operator: '%',
    };

    if (searchBox.addEventListener) {
      //W3C
      searchBox.addEventListener(
        'keyup',
        function () {
          searchfn.apply(this, arguments);
        },
        false,
      );
    } else {
      //IE
      searchBox.attachEvent(
        'onkeyup',
        function () {
          var ev = window.event,
            el = ev.srcElement;
          searchfn.apply(el, arguments);
        },
        false,
      );
    }

    placeholder && wrapper.appendChild(placeholder);
    wrapper.appendChild(searchBox);

    return wrapper;
  }),
    (window.SaxoTCCommon.onChangeFunction = function (data) {
      var query = [],
        item,
        sel,
        prop,
        widgetId = 'widget' + data.WidgetUid + data.Language,
        queryTable = window.SaxoTCCommon[widgetId] || {};

      for (prop in queryTable) {
        if (queryTable.hasOwnProperty(prop)) {
          item = queryTable[prop];
          sel = document.getElementById(prop);
          if (sel && sel.value !== '') {
            query.push({
              id: item.columnType,
              name: item.columnName,
              operator: item.operator,
              value: sel.value,
            });
          }
        }
      }

      if (typeof this.onFilter !== 'undefined') {
        this.onFilter({
          id: widgetId,
          widgetUid: data.WidgetUid,
          language: data.Language,
          query: query,
        });
      }
    });

  window.SaxoTCCommon.renderFilters = function (data) {
    var renderFilters =
        data.Filters.length && data.RenderOptions & 2 && !data.GroupColumn,
      domElements = [],
      widgetId = 'widget' + data.WidgetUid + data.Language,
      queryTable = window.SaxoTCCommon[widgetId] || {},
      selectData,
      select,
      i,
      j,
      option,
      text,
      description,
      selection;

    if (!renderFilters) {
      return [];
    }

    window.SaxoTCCommon[widgetId] = queryTable;

    selection = function (selection, value) {
      var i = 0;
      for (i = 0; i < selection.length; i++) {
        if (selection[i] === value) {
          return true;
        }
      }
      return false;
    };
    var dddd = new Date();

    for (i = 0; i < data.Filters.length; i++) {
      selectData = data.Filters[i];
      select = document.createElement('select');
      select.id = selectData.ColumnName + data.WidgetId + 'filter';
      queryTable[select.id] = {
        columnType: selectData.ColumnType,
        columnName: selectData.ColumnName,
        operator: selectData.Operator,
      };

      select.onchange = function () {
        SaxoTCCommon.onChangeFunction.apply(this, [data]);
      };

      description =
        typeof data.SelectLabel === 'undefined' ? '' : data.SelectLabel;
      selectData.Values.unshift([
        '',
        description + ' ' + selectData.Description + '...',
      ]);

      for (j = 0; j < selectData.Values.length; j++) {
        option = document.createElement('option');
        option.value = selectData.Values[j][0];
        text = document.createTextNode(selectData.Values[j][1]);
        option.appendChild(text);
        option.selected = selection(selectData.Selection, option.value);

        select.appendChild(option);
      }
      domElements.push(select);
    }

    return domElements;
  };

  window.SaxoTCCommon.dataSort = function (rows) {
    if (typeof rows.sort === 'function') {
      rows.sort(function (rowa, rowb) {
        var numberFielda = Number(rowa[0].replace(',', '').replace('%', '')),
          numberFieldb = Number(rowb[0].replace('%', ''));
        if (!isNaN(numberFielda) && !isNaN(numberFieldb)) {
          return numberFielda - numberFieldb;
        }
        var dateFielda = new Date(rowa[0]),
          dateFieldb = new Date(rowb[0]);
        if (
          dateFielda &&
          dateFielda.getFullYear() > 0 &&
          dateFieldb &&
          dateFieldb.getFullYear() > 0
        ) {
          return dateFielda - dateFieldb;
        }
        return rowa[0] < rowb[0] ? -1 : rowa[0] > rowb[0] ? 1 : 0;
      });
    }
  };

  window.SaxoTCCommon.urlencode = function (str) {
    str = (str + '').toString();
    str = encodeURIComponent(str)
      .replace(/!/g, '%21')
      .replace(/'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
      .replace(/\*/g, '%2A')
      .replace(/%20/g, '+')
      .replace(/\\\\/g, '\\');
    return str;
  };
})();
if (isBrowser) {
  window.SaxoTCCommon = window.SaxoTCCommon || {};
  window.SaxoTCCommon.GroupView = {
    getDataDelegate: null,

    renderBody: function (data) {
      var rows = [];
      var htmlRow;
      var groups = data.Filters[0];

      for (var i = 0; i < groups.Values.length; i++) {
        var row = groups.Values[i];

        var value = row[0].split('\\').join('\\\\').split("'").join("\\'");
        var json =
          "{id:'" +
          data.WidgetId +
          data.Language +
          i +
          "',widgetUid:'" +
          data.WidgetUid +
          "',language:'" +
          data.Language +
          "'";
        json +=
          ",query:[{id:'" +
          groups.ColumnType +
          "','name':'" +
          groups.ColumnName +
          "','operator':'" +
          groups.Operator +
          "',value:'" +
          value +
          "'}]}";

        var className = i % 2 === 0 ? 'bgrCol' : 'bgrCol2';

        htmlRow = '<tbody id="' + data.WidgetId + data.Language + i + '" ';
        htmlRow +=
          'onclick="return window.SaxoTCCommon.GroupView.onFilter(' +
          json +
          ');">';
        htmlRow +=
          '<tr><td class="' +
          className +
          '" style="cursor:pointer;" colspan="' +
          data.Columns.length +
          '" >';
        htmlRow += '<span class="grp-img exp-grp-img">+</span>';
        htmlRow += row[1];
        htmlRow += '</td></tr></tbody>';
        rows.push(htmlRow);
      }
      return rows;
    },
    onFilter: function (data) {
      var container = document.getElementById(data.id);
      var expandLabel = container.getElementsByTagName('span')[0];
      expandLabel.innerHTML = '&divide;';
      expandLabel.className = 'grp-img col-grp-img';
      container.setAttribute('onclick', '');
      if (this.getDataDelegate === null) {
        return;
      }

      var result = this.getDataDelegate(data);
      if (result === null || result === undefined) {
        return;
      }

      result.then(function (resultData) {
        SaxoTCCommon.GroupView.updateView(container, resultData);
      });
    },
    updateView: function (container, data) {
      container = document.getElementById(container.id);
      var rows = window.SaxoTCCommon.renderBody(data);
      var tbody = document.createElement('tbody');
      tbody.style.display = '';
      container.parentNode.insertBefore(tbody, container.nextSibling);
      var html = rows.join('');
      var temp = document.createElement('div');
      if (navigator && navigator.userAgent.match(/MSIE/i)) {
        this.MSIEsetTBodyInnerHTML(tbody, html, temp);
        container.onclick = function () {
          return window.SaxoTCCommon.GroupView.toggleDisplay.apply(container);
        };
      } else {
        tbody.innerHTML = html;
        container.addEventListener('click', this.toggleDisplay, true);
      }

      this.updateRowStyles(container);
    },
    updateRowStyles: function (container) {
      var sibling = container.nextSibling;
      var classAttribute = container.getElementsByTagName('td')[0].className;
      var odd = classAttribute.indexOf('bgrCol2') !== -1;

      while (sibling !== null) {
        var node, i, j;
        var trLength = sibling.childNodes.length;
        if (sibling.style.display === 'none') {
          sibling = sibling.nextSibling;
          continue;
        }
        for (i = 0; i < trLength; i++) {
          var tdLength = sibling.childNodes[i].childNodes.length;
          for (j = 0; j < tdLength; j++) {
            node = sibling.childNodes[i].childNodes[j];
            classAttribute = node.className;
            classAttribute = classAttribute
              .split('bgrCol2')
              .join('')
              .split('bgrCol')
              .join('');
            classAttribute = odd
              ? classAttribute + ' bgrCol'
              : classAttribute + ' bgrCol2';
            node.setAttribute('class', classAttribute);
          }
          odd = !odd;
        }
        sibling = sibling.nextSibling;
      }
    },
    toggleDisplay: function (e) {
      var expandLabel = this.getElementsByTagName('span')[0];
      var element = this.nextSibling;
      if (element.style.display === '') {
        element.style.display = 'none';
        expandLabel.className = 'grp-img exp-grp-img';
        expandLabel.innerHTML = '+';
      } else {
        element.style.display = '';
        expandLabel.className = 'grp-img col-grp-img';
        expandLabel.innerHTML = '&divide;';
      }
      window.SaxoTCCommon.GroupView.updateRowStyles(this);
    },
    MSIEsetTBodyInnerHTML: function (tbody, html, temp) {
      temp.innerHTML = '<table><tbody>' + html + '</tbody></table>';
      tbody.parentNode.replaceChild(temp.firstChild.firstChild, tbody);
    },
  };
  window.SaxoTCCommon = window.SaxoTCCommon || {};
  window.SaxoTCCommon.CategoryView = {
    renderView: function (data) {
      var model = this.getModel(data),
        self = this;

      var renderCategories = function (list, level) {
        var i,
          html = '';
        for (i = 0; i < list.length; i++) {
          var item = list[i];
          if (typeof item.categories === 'undefined') {
            html += self.renderCategoryTable(item);
          } else {
            html +=
              '<h' +
              level +
              ' style="margin: 10px 0px 0px 0px;font-size:150%">' +
              item.name +
              '</h' +
              level +
              '>';
            html += renderCategories(item.categories, level + 1);
          }
        }
        return html;
      };

      return renderCategories(model, 4);
    },
    renderCategoryTable: function (item) {
      var html =
        '<h5 style="margin: 5px 0px 5px 5px;font-size:100%">' +
        item.name +
        '</h5>';
      html +=
        '<table class="widget bgrCol" cellspacing="0" style="border-collapse:separate">';
      html +=
        '<tbody><tr><td style="width:50%;vertical-align: top;border: none 0px; padding: 0px;">';
      html +=
        '<table cellspacing="0" class="bottom-border" style="width:100%; margin-top:0px;margin-bottom:2px;"><tbody>';

      for (var i = 0; i < item.rows.length; i++) {
        html += '<tr>';
        for (var j = 0; j < item.rows[i].length; j++) {
          var alignment = item.rows[0][j].Alignment;
          alignment =
            alignment === 1 ? 'Left' : alignment === 3 ? 'Center' : 'Right';
          var className = j === 0 ? 'r1 ' + alignment : alignment;
          html +=
            i === 0
              ? '<th class="h-font ' + className + '">'
              : '<td class="bgrCol ' + className + '">';
          var text =
            typeof item.rows[i][j] === 'string'
              ? item.rows[i][j]
              : item.rows[i][j].Name;
          html += text === '' ? '&nbsp;' : text;
          html += i === 0 ? '</th>' : '</td>';
        }

        html += '</tr>';
      }

      html += '</table></td>';
      html +=
        '<td style="margin:0; padding:0; border: 0;vertical-align: top;" rowspan="' +
        item.rows.length +
        '">';
      html += this.renderPropertyTable(item);
      html += '</td>';

      html += '</td></tr></tbody></table>';

      return html;
    },
    renderPropertyTable: function (item) {
      var html =
        '<table cellspacing="0" class="widget" style="border-top:0;border-right:0;border-bottom:0;padding:0;"><tbody>';

      for (var i = 0; i < item.properties[0].length; i++) {
        var style = i < 2 ? 'border-top-style:none;' : '';
        style += 'text-align:left;white-space:normal;width:25%;';
        if (i % 2 === 0) {
          html += '<tr class="alt">';
        }

        var name =
          item.properties[0][i] === 'string'
            ? item.properties[0][i]
            : item.properties[0][i].Name;

        html +=
          '<td class="h-font bgrCol2" style="' +
          style +
          (i % 2 === 0 ? ' border-left: none 0;' : '') +
          '">' +
          name +
          '</td>';

        html +=
          '<td class="bgrCol" style="' +
          style +
          '">' +
          item.properties[1][i] +
          '</td>';

        if (i % 2 !== 0) {
          html += '</tr>';
        }
      }

      html +=
        i % 2 !== 0
          ? '<td class="bgrCol2">&nbsp;</td><td class="bgrCol">&nbsp</td></tr>'
          : '';
      html += '</tbody></table>';

      return html;
    },
    // Create indexes of category columns
    getCategoryIndex: function (columns) {
      var categoryIndex = [];
      var column;
      for (var i = 0; i < columns.length; i++) {
        column = columns[i];
        if (column.Category > 0) {
          if (typeof categoryIndex[column.Category - 1] === 'undefined')
            categoryIndex[column.Category - 1] = [];
          categoryIndex[column.Category - 1].push(i);
        }
      }
      return categoryIndex;
    },
    splitColumns: function (columns) {
      var groupRow = [],
        propRow = [];
      for (var j = 0; j < columns.length; j++) {
        if (columns[j].Category > 0) {
          continue;
        }
        var tmpRow = columns[j].Group ? groupRow : propRow;
        tmpRow.push(columns[j]);
      }
      return { rows: groupRow, properties: propRow };
    },
    splitRow: function (columns, row) {
      var groupRow = [],
        properties = [],
        tmpRow;
      // create and split rows
      for (var j = 0; j < columns.length; j++) {
        if (columns[j].Category > 0) {
          continue;
        }
        tmpRow = columns[j].Group ? groupRow : properties;
        tmpRow.push(row[j]);
      }

      return { row: groupRow, properties: properties };
    },
    getModel: function (data) {
      var categoryIndex = this.getCategoryIndex(data.Columns);
      var model = new Array();
      var idx, groupRow;
      for (var i = 0; i < data.Rows.length; i++) {
        var row = data.Rows[i];
        var list = model;
        for (var j = 0; j < categoryIndex.length; j++) {
          var name = '';
          for (var k = 0; k < categoryIndex[j].length; k++) {
            idx = categoryIndex[j][k];
            name = name === '' ? row[idx] : name + ' - ' + row[idx];
          }

          idx = this.findObjectByName(list, name);

          var item;
          if (idx < 0) {
            if (j === categoryIndex.length - 1) {
              var groupColumns = this.splitColumns(data.Columns); // Add column headers
              item = {
                name: name,
                rows: [groupColumns.rows],
                properties: [groupColumns.properties],
              };
              groupRow = this.splitRow(data.Columns, row);
              item.rows.push(groupRow.row);
              item.properties.push(groupRow.properties);
            } else {
              item = { name: name, categories: [] };
            }
            list.push(item);
          } else {
            item = list[idx];

            if (j === categoryIndex.length - 1) {
              groupRow = this.splitRow(data.Columns, row);
              item.rows.push(groupRow.row);
            }
          }

          list = item.categories;
        }
      }
      return model;
    },
    findObjectByName: function (array, name) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].name === name) return i;
      }
      return -1;
    },
  };
  window.SaxoTCWidget = {
    appendStyle: function (id, styleText) {
      var head = document.getElementsByTagName('head')[0],
        styleList = document.getElementsByTagName('style'),
        rules = document.createTextNode(styleText);

      var style = null;
      for (var i = 0; i < styleList.length; i++) {
        style = styleList[i].id === 'style' + id ? styleList[i] : style;
      }

      style && head.removeChild(style);

      // Create new style element
      style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'style' + id;
      head.appendChild(style);

      if (style.styleSheet) {
        style.styleSheet.cssText = rules.nodeValue;
      } else {
        style.appendChild(rules);
      }
    },

    getData: function (url, id, targetId, lang, query) {
      var s = document.createElement('script');
      s.src = url + '?uid=' + id + '&lang=' + lang;
      if (typeof query !== 'undefined') {
        s.src += '&query=' + escape(query);
      }
      if (typeof targetId !== 'undefined') {
        s.src += '&target=' + targetId;
      }
      s.type = 'text/javascript';
      document.getElementsByTagName('body')[0].appendChild(s);
    },
    handleErrors: function (container, data) {
      if (data.Errors.length > 0) {
        var error = document.createTextNode(data.Errors[0]);
        container.appendChild(error);
        return true;
      }
      return false;
    },
    updateContent: function (id, data) {
      var container = document.getElementById(id);

      var newFilters = window.SaxoTCCommon.renderFilters(data),
        oldFilters = container.getElementsByTagName('select'),
        filterParent = document.getElementById('filters' + id);

      this.updateSpinner(id);

      if (this.handleErrors(container, data)) {
        return;
      }

      for (
        var i = oldFilters && oldFilters.length - 1;
        oldFilters && i >= 0;
        i--
      ) {
        filterParent.removeChild(oldFilters[i]);
      }

      for (i = 0; newFilters && i < newFilters.length; i++) {
        newFilters[i].onFilter = this.onFilter;
        filterParent.appendChild(newFilters[i]);
      }

      return data.GroupColumn === 0
        ? this.updateTableView(container, data)
        : window.SaxoTCCommon.GroupView.updateView(container, data);
    },
    updateTableView: function (container, data) {
      var table = container.getElementsByTagName('table')[0],
        parent,
        divs;
      if (typeof table !== 'undefined') {
        // Empty table if
        parent = table.parentNode;
        parent.removeChild(table);

        var tbody = table.getElementsByTagName('tbody')[0];
        if (typeof tbody !== 'undefined') {
          while (tbody.hasChildNodes()) {
            tbody.removeChild(tbody.firstChild);
          }
          table.removeChild(tbody);
        }
      } else {
        divs = container.getElementsByTagName('div');
        parent = divs[divs.length - 1];
      }

      parent.innerHTML = window.SaxoTCCommon.renderTable(data);
    },
    showContent: function (id, data) {
      var container = document.getElementById(id);

      this.updateSpinner(id);

      if (this.handleErrors(container, data)) {
        return;
      }

      var tableHTML = window.SaxoTCCommon.renderTable(data),
        selects = window.SaxoTCCommon.renderFilters(data),
        search = SaxoTCCommon.renderSearchBox(data),
        styles = window.SaxoTCCommon.renderStyles('div#' + id, data);

      this.appendStyle(id, styles);

      var div = document.createElement('div'),
        i;
      div.id = 'filters' + id;

      for (i = 0; i < selects.length; i++) {
        div.appendChild(selects[i]);
        selects[i].onFilter = this.onFilter;
      }

      if (search) {
        search.getElementsByTagName('input')[0].onFilter = this.onFilter;
        container.appendChild(search);
      }

      container.appendChild(div);

      div = document.createElement('div');
      div.innerHTML = tableHTML;
      container.appendChild(div);
    },
    onFilter: function (data) {
      var query = '';
      for (var j = 0; j < data.query.length; j++) {
        var filter =
          data.query[j].name +
          data.query[j].operator +
          "'" +
          window.SaxoTCCommon.urlencode(
            data.query[j].value.split("'").join("\\'"),
          ) +
          "'";
        query += query === '' ? filter : '&' + filter;
      }

      var spinner = window.SaxoTCWidget.getSpinner(
        'spinner' + data.id,
        '16px',
        '100px',
      );

      var container = document.getElementById(data.id);
      if (container.tagName === 'TBODY') {
        // grouping
        var td = container.getElementsByTagName('td')[0];
        var br = document.createElement('br');
        td.appendChild(br);
        td.appendChild(spinner);
      } else if (document.getElementById('spinner' + data.id) === null) {
        // filtering
        var div = container.getElementsByTagName('div')[0];
        spinner.style.width = '100px';
        div.appendChild(spinner);
      } else {
        var spinner = document.getElementById('spinner' + data.id);
        spinner.setAttribute(
          'data-count',
          parseInt(spinner.getAttribute('data-count'), 10) + 1,
        );
      }

      updateWidget(data.id, data.widgetUid, data.language, query);
    },
    getSpinner: function (id, height, width) {
      var div = document.createElement('div');
      div.id = id;
      div.setAttribute('data-count', '1');
      div.style.display = 'inline-block';
      div.style.minWidth = width;
      div.style.minHeight = height;
      div.style.width = '100%';
      div.style.background =
        "url('" + window.SaxoTCWidget.loaderUrl + "') no-repeat";
      div.style.backgroundPosition = 'center ';
      return div;
    },
    updateSpinner: function (id) {
      var filterDiv = document.getElementById('spinner' + id),
        count = parseInt(filterDiv.getAttribute('data-count'));

      filterDiv.setAttribute('data-count', --count);
      !count && filterDiv.parentNode.removeChild(filterDiv);
    },
  };

  window.SaxoTCCommon.GroupView.getDataDelegate = window.SaxoTCWidget.onFilter;
  window.SaxoTCWidget.loaderUrl =
    'https://mitsweb.iitech.dk/PPSSWS/Resources/loader100x16.gif';
}

export function drawWidget(id, lang, query) {
  if (isBrowser) {
    var div = window.SaxoTCWidget.getSpinner(
      'spinnerwidget' + id + lang,
      '32px',
      '100px',
    );
    let container;
    if (typeof document !== `undefined`) {
      container = document.getElementById('widget' + id + lang);
    }
    if (container) {
      container.style.width = '100%';
      container.appendChild(div);

      window.SaxoTCWidget.getData(
        'https://mitsweb.iitech.dk/PPSSWS/WidgetView.aspx/Index',
        id,
        container.id,
        lang,
        query,
      );
    }
  }
}
export function updateWidget(targetId, id, lang, query) {
  if (isBrowser) {
    window.SaxoTCWidget.getData(
      'https://mitsweb.iitech.dk/PPSSWS/WidgetView.aspx/Data',
      id,
      targetId,
      lang,
      query,
    );
  }
}
