import React from 'react';

import AssetLink from '@/components/AssetLink';
import InternalLink from '@/components/InternalLink';
import ExternalLink from '@/components/ExternalLink';
import { LinkStyle } from '@/components/Link';

import { generateSlug } from '@/utils/SlugUtils';

export const renderInlineAssetLink = (node, size = null) => (
  <AssetLink
    inline
    underline
    {...node.data.target}
    linkStyle={LinkStyle.INLINE}
    size={size}
    margin='1.2rem 0'
  />
);

export const renderInlineInternalLink = (node, size = null) => {
  if (!node?.data?.target?.page?.name) {
    return null;
  }

  return (
    <InternalLink
      inline
      to={generateSlug(node.data.target.page)}
      linkStyle={LinkStyle.INLINE}
      size={size}
      {...node.data.target}
    >
      {node.data.target.linkText
        ? node.data.target.linkText
        : node.data.target.page.name}
    </InternalLink>
  );
};

const handleTextNode = (item) => {
  const { value, marks } = item;
  const isBold = marks?.[0]?.type === 'bold';
  const itemValue = isBold ? <strong>{value}</strong> : value;
  return itemValue;
};

const handleHyperlinkNode = (item) => {
  const { content, data } = item;

  if (!data?.uri || !content?.[0]?.value) {
    return null;
  }

  return (
    <ExternalLink url={data.uri} linkStyle={LinkStyle.INLINE}>
      {content?.[0]?.value}
    </ExternalLink>
  );
};

const handleEmbeddedEntryInlineNode = (item) => {
  const { data } = item;

  switch (data?.target?.__typename) {
    case 'ContentfulAssetLink':
      return renderInlineAssetLink(item, 'md');
    case 'ContentfulInternalLink':
      return renderInlineInternalLink(item, 'md');
    default:
      return null;
  }
};

export const getCellContentValue = (item) => {
  switch (item.nodeType) {
    case 'hyperlink':
      return handleHyperlinkNode(item);
    case 'embedded-entry-inline':
      return handleEmbeddedEntryInlineNode(item);
    case 'text':
    default:
      return handleTextNode(item);
  }
};
